import DateInputGroup from '@root/core/src/components/date-input-group';
import DobDate from '@root/core/src/models/dob-date';
import ErrorMessage from '@root/core/src/components/error-message';
import PrefillRequest from '@root/prefill/src/models/prefill-request';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { capitalize } from '@root/core/src/utils/strings';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_EVENTS = {
  DOB_ERROR: 'DOB_ERROR',
  FOCUSED_ON_DOB_FIELD: 'FOCUSED_ON_DOB_FIELD',
};

export default function DobInputGroup({
  hidden,
  onUpdatePrefillRequest,
  onUpdateProfileParams,
  prefillRequest,
  profileParams,
  trackEvent,
  ...restProps
}) {
  const strings = useI18nNext('components.bindStartEntry.dobInputGroup');
  const [date, setDate] = useState(new DobDate(prefillRequest.dob || ''));
  const [errorMessage, setErrorMessage] = useState();

  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  const latestTimeout = useRef();

  useEffect(() => {
    if (date.day || date.month || date.year) {
      latestTimeout.current = setTimeout(() => {
        let message = null;
        Object.keys(strings.dateParts).some((datePart) => {
          const capitalized = capitalize(datePart);
          if (date[datePart] && !date[`isValid${capitalized}`]()) {
            message = strings.errors.missing({
              datePart,
              digits: datePart === strings.dateParts.year ? 4 : 2,
            });
            return true;
          }

          return false;
        });

        if (!message && date.day && date.month && date.year) {
          if (!date.dateExists()) {
            message = strings.errors.invalid({ dateString: date.toDisplayString() });
          }

          if (!date.isYoungEnough()) {
            message = strings.errors.maxAge({ maxAge: DobDate.DEFAULT_MAX_AGE });
          }

          if (!date.isOldEnough()) {
            message = strings.errors.minAge({ minAge: DobDate.DEFAULT_MIN_AGE });
          }
        }
        setErrorMessage(message);
      }, 350);
    }

    return () => clearTimeout(latestTimeout.current);
  }, [date, strings.dateParts, strings.errors]);

  useEffect(() => {
    if (errorMessage) {
      trackEvent(ANALYTICS_EVENTS.DOB_ERROR, { errorMessage });
    }
  }, [errorMessage, trackEvent]);

  const handleChange = useCallback((field) => async (value) => {
    const updatedDate = date.set(field, value);
    setErrorMessage(null);
    setDate(updatedDate);
    const updatedProfileParams = ProfileDriverService.setDriverValue(
      profileParams,
      profileParams.getPniDriver().universalDriverId, // We're only concerned about the primary driver on the start entry page
      'dob',
      updatedDate
    );
    const updatedPrefillRequest = prefillRequest.set(
      'dob',
      updatedDate.toString()
    );
    onUpdateProfileParams(updatedProfileParams);
    onUpdatePrefillRequest(updatedPrefillRequest);
  }, [date, onUpdatePrefillRequest, onUpdateProfileParams, prefillRequest, profileParams, setDate]);

  const handleFocus = useCallback((dobField) => {
    trackEvent(ANALYTICS_EVENTS.FOCUSED_ON_DOB_FIELD, { dobField });
  }, [trackEvent]);

  if (hidden) {
    return null;
  }

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      {!!errorMessage && <ErrorMessage message={errorMessage} />}
      <DateInputGroup
        date={date}
        inputStyle={styles.inputStyle}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </div>
  );
}

export const stylesGenerator = ({ infoColor }) => StyleSheet.create({
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading4(),
  },
  inputStyle: {
    caretColor: infoColor,
    ':focus': {
      borderColor: infoColor,
    },
  },
});

DobInputGroup.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onUpdatePrefillRequest: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  prefillRequest: PropTypes.instanceOf(PrefillRequest).isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  trackEvent: PropTypes.func.isRequired,
};
