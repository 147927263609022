/* eslint react/jsx-no-bind: 0 */

import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import BindParams from '@root/quotes/src/models/bind-params';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCopy from '@root/quotes/src/components/coverage/coverage-copy';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ListCard, { ListCardItem } from '@root/core/src/components/list-card';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import QuotingRules from '@root/quotes/src/models/quoting-rules';
import React, { Component } from '@root/vendor/react';
import RentalCoverageDisclaimer from '@root/quotes/src/components/coverage-options/rental-coverage-disclaimer';
import VehicleSelectionCard from '@root/quotes/src/components/coverage-options/vehicle-selection-card';

export default class CoverageSectionRentalCar extends Component {
  static propTypes = {
    bindParams: PropTypes.instanceOf(BindParams).isRequired,
    cardHeaderBackgroundColor: PropTypes.string,
    coverageSelections: PropTypes.object,
    customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
    dropdownIndicator: PropTypes.string,
    IconComponent: PropTypes.func,
    mobileSelectedOptionStyles: PropTypes.object,
    onAcceptCoverages: PropTypes.func.isRequired,
    onCoverageChange: PropTypes.func.isRequired,
    onCoverageSelectClose: PropTypes.func.isRequired,
    onCoverageSelectOpen: PropTypes.func.isRequired,
    onDeclineCoverages: PropTypes.func.isRequired,
    onVehicleToggle: PropTypes.func.isRequired,
    optionStyles: PropTypes.object,
    primaryBrandColor: PropTypes.string,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
    quotingRules: PropTypes.instanceOf(QuotingRules).isRequired,
    secondaryBrandColor: PropTypes.string,
    selectBoxFocusedStyles: PropTypes.object,
    selectBoxStyles: PropTypes.object,
    styleOverrides: PropTypes.object,
  };

  compCoverageName = Coverage.getLocaleName({
    symbol: Coverage.Symbols.COMPREHENSIVE,
    market: this.props.profileParams.market(),
  });

  collCoverageName = Coverage.getLocaleName({
    symbol: Coverage.Symbols.COLLISION,
    market: this.props.profileParams.market(),
  });

  rentalCoverageName = Coverage.getLocaleName({
    symbol: Coverage.Symbols.RENTAL,
    market: this.props.profileParams.market(),
  });

  copyByMarket = {
    default: {
      description: `Included vehicles will have ${this.rentalCoverageName} coverage.`,
      title: `${this.rentalCoverageName}`,
      disclaimer: `You need to add ${this.compCoverageName} and ${this.collCoverageName} to your plan before you can add ${this.rentalCoverageName} coverage.`,
    },
  };

  _handleVehicleToggle = (vehicle, selected) => {
    const rental = this.props.customQuote.getCoverage(Coverage.Symbols.RENTAL);
    this.props.onVehicleToggle(vehicle, selected, [rental]);
  }

  _copy = (name) => {
    const copy = this.copyByMarket[this.props.profileParams.market()] ?? this.copyByMarket['default'];

    return copy[name];
  };

  render() {
    const {
      cardHeaderBackgroundColor,
      coverageSelections,
      customQuote,
      dropdownIndicator,
      IconComponent,
      mobileSelectedOptionStyles,
      onCoverageChange,
      onCoverageSelectClose,
      onCoverageSelectOpen,
      onAcceptCoverages,
      onDeclineCoverages,
      optionStyles,
      quotingRules,
      profileParams,
      primaryBrandColor,
      secondaryBrandColor,
      selectBoxFocusedStyles,
      selectBoxStyles,
      styleOverrides,
    } = this.props;

    const rental = customQuote.getCoverage(Coverage.Symbols.RENTAL);
    const { coveredVins: collAndCompCoveredVins, hasSelectedVehicles: hasCollAndComp } = customQuote.getDeductibleInfo();

    let onRemoveCoverage = null;

    if (customQuote.coverageIsDeclinable(rental)) {
      onRemoveCoverage = () => onDeclineCoverages([rental]);
    }

    if (quotingRules.rules.rentalRequiresCollAndComp && (!coverageSelections[Coverage.Symbols.RENTAL].length || !hasCollAndComp)) {
      return (
        <CoverageGroup
          heading={this._copy('title')}
          hideDivider={true}
          styleOverrides={styleOverrides}
        >
          <ListCard>
            <ListCardItem>
              {this._copy('disclaimer')}
            </ListCardItem>
          </ListCard>
        </CoverageGroup>
      );
    }

    if (!rental || !coverageSelections[Coverage.Symbols.RENTAL]) {
      return null;
    }

    const selectableVehicles = quotingRules.rules.rentalRequiresCollAndComp ? profileParams.vehicles.filter((vehicle) => collAndCompCoveredVins.includes(vehicle.vin)) : profileParams.vehicles;

    return (
      <CoverageGroup
        heading={this._copy('title')}
        hideDivider={true}
        styleOverrides={styleOverrides}
      >
        <VehicleSelectionCard
          description={this._copy('description')}
          isCoverageAdded={!rental.declined}
          onToggle={this._handleVehicleToggle}
          selectedVins={rental.coveredVins}
          toggleSelectedColor={secondaryBrandColor}
          vehicles={selectableVehicles}
        />
        <AddCoverageCard
          description={
            <CoverageCopy
              coverage={rental}
              market={profileParams.market()}
            />}
          heading={
            <CoverageTitle
              coverage={rental}
              market={profileParams.market()}
            />}
          IconComponent={IconComponent}
          isCoverageAdded={!rental.declined}
          onAddCoverage={() => onAcceptCoverages([rental])}
          primaryBrandColor={primaryBrandColor}
        />
        <CoverageSelectionCard
          description={
            <CoverageCopy
              coverage={rental}
              market={profileParams.market()}
            />}
          headerBackgroundColor={cardHeaderBackgroundColor}
          heading={
            <CoverageTitle
              coverage={rental}
              market={profileParams.market()}
            />}
          isCoverageAdded={!rental.declined}
          onRemoveCoverage={onRemoveCoverage}
          vehiclesSelected={!!rental.coveredVins.length}
        >
          <QuotesSelect
            dropdownIndicator={dropdownIndicator}
            dropdownIndicatorColor={secondaryBrandColor}
            inputId={'rental-coverage-select'}
            inputName={'rental-coverage-select'}
            mobileSelectedOptionStyles={mobileSelectedOptionStyles}
            onChange={(id) => onCoverageChange(id, Coverage.Symbols.RENTAL)}
            onClose={onCoverageSelectClose}
            onOpen={onCoverageSelectOpen}
            options={coverageSelections[Coverage.Symbols.RENTAL]}
            optionStyles={optionStyles}
            placeholder={'Select option'}
            selectBoxFocusedStyles={selectBoxFocusedStyles}
            selectBoxStyles={selectBoxStyles}
            selectedValue={rental.id}
          />
        </CoverageSelectionCard>
        <RentalCoverageDisclaimer
          market={this.props.profileParams.mailingAddress.state}
        />
      </CoverageGroup>
    );
  }
}
