import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import Select from '@root/bind.joinroot.com/src/components/select';
import useBranding from '@root/bind.joinroot.com/src/context/branding';

const options = BillingCycle.optionsForSelect();

const BillingCycleSelect = ({ billingCycle, updateBillingCycle }) => {
  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  return (
    <Select
      inputId={'billingCycle'}
      inputName={'billingCycle'}
      onChange={updateBillingCycle}
      options={options}
      optionStyles={styles.optionStyles}
      persistLabel={true}
      placeholder={'Payment frequency'}
      selectBoxFocusedStyles={styles.selectBoxFocusedStyles}
      selectBoxStyles={styles.selectBoxStyles}
      selectedValue={billingCycle}
    />
  );
};

const stylesGenerator = ({ infoColor = Colors.rootOrange() }) => ({
  mobileSelectedOptionStyles: {
    background: Colors.gray20(),
    color: Colors.black(),
  },
  optionStyles: {
    ':hover, :hover > span': {
      background: Colors.gray20(),
      color: Colors.black(),
    },
  },
  selectBoxStyles: {
    ':focus': {
      borderColor: infoColor,
    },
  },
  selectBoxFocusedStyles: {
    borderColor: infoColor,
  },
});

BillingCycleSelect.propTypes = {
  billingCycle: PropTypes.oneOf([
    BillingCycle.ANNUALLY,
    BillingCycle.FULL_TERM,
    BillingCycle.MONTHLY,
  ]),
  updateBillingCycle: PropTypes.func.isRequired,
};

export default BillingCycleSelect;
