import InputError from '@root/core/src/components/input-error';
import KeyCode from '@root/core/src/utils/keycode';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SelectBox from '@root/core/src/components/select/select-box';
import ZIndex from '@root/core/src/utils/z-index';
import chevronDownRootOrange from '@root/core/src/assets/chevron-down-root-orange.svg';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { SelectOption } from '@root/core/src/components/select/select-option';

export default class Select extends Component {
  static AutoComplete = {
    OFF: 'off',
  }

  static propTypes = {
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownIndicator: PropTypes.string,
    dropdownIndicatorColor: PropTypes.string,
    errorLabel: PropTypes.string,
    inputId: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          spacer: PropTypes.bool.isRequired,
        }),
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          subLabel: PropTypes.string,
          value: PropTypes.string.isRequired,
        }),
      ])
    ).isRequired,
    optionStyles: PropTypes.object,
    persistLabel: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    selectBoxFocusedStyles: PropTypes.object,
    selectBoxStyles: PropTypes.object,
    selectedValue: PropTypes.string.isRequired,
    shouldRenderNativeMobile: PropTypes.bool,
    shouldRenderNativeSelectError: PropTypes.bool,
    wrapperStyles: PropTypes.object,
  }

  static defaultProps = {
    autoComplete: Select.AutoComplete.OFF,
    disabled: false,
    shouldRenderNativeMobile: true,
  }

  state = {
    placeholder: this.props.placeholder,
    isOpen: false,
    pressedKeys: [],
    autoComplete: this.props.autoComplete,
  }

  constructor(props) {
    super(props);

    this._wrapperRef = React.createRef();
    this._dropdownRef = React.createRef();
    this._optionRefs = this.props.options.filter((option) => !option.spacer).map(React.createRef);
  }

  componentDidMount() {
    document.addEventListener('click', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleClickOutside);
  }

  componentDidUpdate(prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      this.props.onOpen?.();
    }
  }

  get _selectableOptions() {
    return this.props.options.filter((option) => !option.spacer);
  }

  _handleClickOutside = (event) => {
    if (!this._isInsideContainer(this._wrapperRef, event.target) && !this._isInsideContainer(this._dropdownRef, event.target)) {
      this._closeDropdown();
      this.setState({
        autoComplete: this.props.autoComplete,
      });
    }
  }

  _isInsideContainer(container, target) {
    return container.current && container.current.contains(target);
  }

  _clearPressedKeys() {
    this.setState({
      pressedKeys: [],
    });
  }

  _selectOption = (option) => {
    this._closeDropdown();
    this.props.onChange(option.value);
  }

  _handleClick = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
        autoComplete: Select.AutoComplete.OFF,
      }, () => {
        const ref = this._getOptionRef(this._getOptionByValue(this.props.selectedValue)) || this._optionRefs[0];
        ref.current?.focus();
      });
    } else {
      this._closeDropdown();
    }
  }

  _closeDropdown() {
    this.setState({
      isOpen: false,
      pressedKeys: [],
    });
  }

  _optionElements() {
    return this._optionRefs.map((optionRef) => optionRef.current);
  }

  _getActiveOptionIndex() {
    return this._optionElements().indexOf(document.activeElement);
  }

  _getOptionRef(option = {}) {
    const optionIndex = this._selectableOptions.findIndex((o) => option.value === o.value);
    return this._optionRefs[optionIndex];
  }

  _focusOption(option) {
    this._getOptionRef(option)?.current?.focus();
  }

  _focusNextOption() {
    const activeOptionIndex = this._getActiveOptionIndex();
    let nextOptionIndex = activeOptionIndex;
    if (activeOptionIndex < this._selectableOptions.length - 1) {
      nextOptionIndex++;
    }
    this._focusOption(this._selectableOptions[nextOptionIndex]);
  }

  _focusPreviousOption() {
    const activeOptionIndex = this._getActiveOptionIndex();
    let previousOptionIndex = activeOptionIndex;
    if (activeOptionIndex < 1) {
      previousOptionIndex = 0;
    } else {
      previousOptionIndex--;
    }
    this._focusOption(this._selectableOptions[previousOptionIndex]);
  }

  _handleCharacterPress(keyCode) {
    const keyChar = String.fromCharCode(keyCode);
    if (keyChar) {
      this.setState({
        pressedKeys: [...this.state.pressedKeys, keyChar],
      }, () => this._focusMostRelevantOption());
    }
  }

  _handleKeypress = (event) => {
    const keyCode = KeyCode.fromEvent(event);
    if (keyCode === KeyCode.KEYCODES.TAB && !this.state.isOpen) { return; }

    event.stopPropagation();
    event.preventDefault();

    if (keyCode === KeyCode.KEYCODES.DOWN) {
      this._clearPressedKeys();
      this._focusNextOption();
    } else if (keyCode === KeyCode.KEYCODES.UP) {
      this._clearPressedKeys();
      this._focusPreviousOption();
    } else if (keyCode === KeyCode.KEYCODES.ENTER || keyCode === KeyCode.KEYCODES.TAB) {
      event.currentTarget.click();
    } else if (keyCode === KeyCode.KEYCODES.ESC) {
      this._closeDropdown();
    } else if (KeyCode.isCharacter(keyCode)) {
      if (!this.state.isOpen) {
        event.currentTarget.click();
      }
      this._handleCharacterPress(keyCode);
    } else {
      this._clearPressedKeys();
    }
  }

  _focusMostRelevantOption() {
    const searchString = this.state.pressedKeys.join('');
    const matchingOptions = this._selectableOptions.filter((option) => {
      return option.label.toUpperCase().startsWith(searchString);
    });

    if (matchingOptions.length) {
      this._focusOption(matchingOptions[0]);
    }
  }

  _getSelectBoxStyles(isMobile = false) {
    const selectBoxStyles = [styles.selectBox, this.props.selectBoxStyles, {
      backgroundImage: `url(${this.props.dropdownIndicator || chevronDownRootOrange})`,
    }];
    if (this.state.isOpen) {
      selectBoxStyles.push(styles.selectBoxFocused, this.props.selectBoxFocusedStyles);
    }

    if (this.props.selectedValue) {
      selectBoxStyles.push(styles.selectBoxWithSelection);
    }

    if (isMobile) {
      selectBoxStyles.push(styles.selectBoxMobile);
    }

    if (this.props.errorLabel) {
      selectBoxStyles.push(styles.selectBoxError);
    }

    return selectBoxStyles;
  }

  _getOptionByValue(value = '') {
    return this._selectableOptions.find((option) => option.value.toUpperCase() === value.toUpperCase());
  }

  _getOptionByLabel(label = '') {
    return this._selectableOptions.find((option) => option.label.toUpperCase() === label.toUpperCase());
  }

  _onInputChange = (event) => {
    const inputValue = event.target.value;
    const option = this._getOptionByValue(inputValue) || this._getOptionByLabel(inputValue);

    if (option) {
      this.props.onChange(option.value);
    }
  }

  _getLabelId() {
    return this.props.inputId + '-label';
  }

  _getLabelStyles() {
    const labelStyles = [styles.labelFull];
    if (this.props.selectedValue !== '') {
      labelStyles.push(styles.labelSmall);
    }

    return labelStyles;
  }

  _optionIsActive(option) {
    return this._getOptionRef(option) === document.activeElement;
  }

  _onChangeMobile = (event) => {
    this.props.onChange(event.target.value);
  }

  _renderOptions() {
    let spacerCount = 0;
    return this.props.options.map((option, index) => {
      if (option.spacer) {
        spacerCount += 1;
        return (
          <div
            css={styles.optionSpacer}
            key={`spacer-${spacerCount}`}
          />
        );
      }

      return (
        <SelectOption
          forwardRef={this._optionRefs[index - spacerCount]}
          key={option.value}
          onClick={this._selectOption}
          onKeyDown={this._handleKeypress}
          option={option}
          overrideStyles={this.props.optionStyles}
          selectedValue={this.props.selectedValue}
        />);
    });
  }

  _renderDropdown() {
    if (!this.state.isOpen) {
      return null;
    }
    return (
      <div
        css={styles.dropdown}
        data-testid={'select-dropdown'}
        ref={this._dropdownRef}
      >
        <div css={styles.placeholderStyles}>{this.props.placeholder}</div>
        {this._renderOptions()}
        {this.props.options.length > 5 && <div css={styles.bottomFade} />}
      </div>
    );
  }

  _renderCustomSelect() {
    return (
      <div
        css={[
          ...this.props.shouldRenderNativeMobile ? [styles.hideWhenMobile] : [],
          this.props.wrapperStyles,
        ]}
      >
        <SelectBox
          aria-labelledby={this._getLabelId()}
          autoComplete={this.state.autoComplete}
          disabled={this.props.disabled}
          dropdownIndicator={this.props.dropdownIndicator}
          dropdownIndicatorColor={this.props.dropdownIndicatorColor}
          errorLabel={this.props.errorLabel}
          inputId={this.props.inputId}
          isOpen={this.state.isOpen}
          name={this.props.inputName}
          onChange={this._onInputChange}
          onClick={this._handleClick}
          onFocus={this.props.onOpen}
          onKeyDown={this._handleKeypress}
          persistLabel={this.props.persistLabel}
          placeholder={this.props.placeholder}
          selectBoxFocusedStyles={this.props.selectBoxFocusedStyles}
          selectBoxStyles={[this.props.selectBoxStyles, this.props.selectedValue ? styles.selectBoxWithSelection : null]}
          value={this._getOptionByValue(this.props.selectedValue)?.label || ''}
          wrapperRef={this._wrapperRef}
          wrapperStyles={this.props.wrapperStyles}
        >
          {this._renderDropdown()}
        </SelectBox>
      </div>
    );
  }

  _renderNativeSelect() {
    return (
      <div css={[styles.wrapper, styles.nativeWrapper, this.props.wrapperStyles]}>
        <select
          css={this._getSelectBoxStyles(true)}
          onChange={this._onChangeMobile}
          onFocus={this.props.onOpen}
          value={this.props.selectedValue || ''}
        >
          <option
            disabled={true}
            value={''}
          >
            {this.props.placeholder}
          </option>
          {this._selectableOptions.map((option) => {
            return (
              <option
                key={option.label}
                value={option.value}
              >
                {option.label}
              </option>
            );
          })}
        </select>
        {this.props.errorLabel && this.props.shouldRenderNativeSelectError &&
          <InputError message={this.props.errorLabel} />
        }
      </div>
    );
  }

  render() {
    return (
      <>
        {this._renderCustomSelect()}
        {!!this.props.shouldRenderNativeMobile && this._renderNativeSelect()}
      </>
    );
  }
}

const labelSmallStyle = {
  ...Theme.inputLabel(),
  top: 0,
  transform: 'translateY(7px)',
  alignItems: 'flex-start',
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: 65,
    position: 'relative',
  },
  placeholderStyles: {
    ...Theme.paragraph1({ bold: true }),
    color: Colors.black(),
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectBox: {
    ...Theme.input(),
    caretColor: 'transparent',
    width: '100%',
    height: 65,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 38px 0 15px',
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray40()}`,
    color: Colors.gray50(),
    cursor: 'pointer',
    backgroundPosition: 'bottom 50% right 12px',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectBoxWithSelection: {
    color: Colors.black(),
  },
  selectBoxMobile: {
    backgroundColor: Colors.white(),
    paddingTop: 0,
    appearance: 'none',
  },
  nativeWrapper: {
    ...Responsive.md({
      display: 'none',
    }),
  },
  hideWhenMobile: {
    display: 'none',
    ...Responsive.md({
      display: 'inherit',
    }),
  },
  selectBoxFocused: {
    ...Shadows.hoverShadow(),
    borderColor: Colors.black(),
    outline: 'none',
  },
  dropdown: {
    ...Theme.input(),
    position: 'absolute',
    marginTop: 5,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray40()}`,
    color: Colors.gray50(),
    background: Colors.white(),
    cursor: 'pointer',
    zIndex: ZIndex.DROPDOWN_MENU,
    ...Shadows.hoverShadow(),
    maxHeight: 315,
    overflowY: 'auto',
    '> div:not(:last-child)': {
      borderBottom: `1px solid ${Colors.gray20()}`,
    },
  },
  bottomFade: {
    position: 'sticky',
    width: '100%',
    height: 18,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)',
  },
  labelFull: {
    ...Theme.input({
      placeholder: true,
    }),
    pointerEvents: 'none',
    paddingLeft: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  labelSmall: labelSmallStyle,
  optionSpacer: {
    height: 30,
  },
  selectBoxError: {
    borderColor: Colors.error(),
  },
});
