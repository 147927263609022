import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import TriageGetAppButton from '@root/triage/src/components/triage-get-app-button';
import TriageSendTextForm from '@root/triage/src/components/triage-send-text-form';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function TriageGetApp({
  formButtonText,
  infoBrandColor,
  inline = false,
  onSubmit,
  primaryBrandColor,
  trackClick,
}) {
  return (
    <div css={styles.wrapper}>
      <div css={styles.textMessageForm}>
        <TriageSendTextForm
          buttonText={formButtonText}
          infoBrandColor={infoBrandColor}
          inline={inline}
          onSubmit={onSubmit}
          primaryBrandColor={primaryBrandColor}
          trackClick={trackClick}
        />
      </div>
      <div css={styles.appLink}>
        <TriageGetAppButton
          infoBrandColor={infoBrandColor}
          primaryBrandColor={primaryBrandColor}
          trackClick={trackClick}
        />
      </div>
    </div>
  );
}

TriageGetApp.propTypes = {
  formButtonText: PropTypes.string.isRequired,
  infoBrandColor: PropTypes.string,
  inline: PropTypes.bool,
  onSubmit: PropTypes.func,
  primaryBrandColor: PropTypes.string,
  trackClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 70,
  },
  appLink: {
    marginTop: 50,
    marginBottom: 20,
    ...Responsive.md({
      display: 'none',
    }),
  },
  textMessageForm: {
    display: 'none',
    ...Responsive.md({
      display: 'block',
    }),
  },
});
