import AuthService from '@root/core/src/services/auth-service';
import LoaderButton from '@root/core/src/components/loader-button';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import TriageForm from '@root/triage/src/components/triage-form';
import TriageSendTextSuccess from '@root/triage/src/components/triage-send-text-success';
import sendWebAppTextMessageConfiguration from '@root/triage/src/api/send-web-app-text-message/send-web-app-text-message-configuration';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidPhoneNumber } from '@root/core/src/utils/validators';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('phoneNumber', 'Please enter your phone number.'),
  isValidPhoneNumber('phoneNumber'),
];

export default function TriageSendTextForm({
  buttonText,
  infoBrandColor,
  inline, onSubmit = () => {},
  primaryBrandColor,
  trackClick,
}) {
  const userContext = AuthService.getCurrentUserContext();
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const [sendTextMessage, isSending] = useImperativeNetworkRequest(sendWebAppTextMessageConfiguration);
  const {
    values: { phoneNumber }, isValid, getFieldProps, createSubmitHandler,
  } = useForm({
    validations,
    initialValues: {
      phoneNumber: userContext.phoneNumber?.replace(/\D/g, '') || '',
    },
  });

  const handleSubmit = async () => {
    if (!isValid) { return; }

    trackClick('TEXT_MESSAGE_SUBMIT');
    const result = await sendTextMessage(phoneNumber);

    if (result.isSuccess()) {
      setPhoneNumberSubmitted(true);
      onSubmit();
    }
  };

  const styles = useMemo(() => stylesGenerator({
    infoBrandColor,
    inline,
    primaryBrandColor,
  }), [infoBrandColor, inline, primaryBrandColor]);

  return (
    <TriageForm onSubmit={createSubmitHandler(handleSubmit)}>
      {
        phoneNumberSubmitted && (
          <TriageSendTextSuccess
            inline={inline}
          />)
      }
      {!phoneNumberSubmitted && (
        <div
          css={styles.formContainer}
          data-testid={'send-text-form'}
        >
          <div css={styles.phoneNumberInputContainer}>
            <PhoneNumberInput
              inputStyle={styles.input}
              label={'Phone number'}
              {...getFieldProps('phoneNumber')}
            />
          </div>
          <LoaderButton
            disabled={!isValid}
            isLoading={isSending}
            loadingText={'Sending...'}
            styles={styles.buttonOverrides}
          >
            {buttonText}
          </LoaderButton>
        </div>
      )}
    </TriageForm>
  );
}

TriageSendTextForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  infoBrandColor: PropTypes.string,
  inline: PropTypes.bool,
  onSubmit: PropTypes.func,
  primaryBrandColor: PropTypes.string,
  trackClick: PropTypes.func.isRequired,
};

const stylesGenerator = ({
  infoBrandColor = Colors.rootOrange(),
  inline,
  primaryBrandColor = Colors.rootOrange(),
}) => ({
  // common styles
  buttonOverrides: {
    backgroundColor: primaryBrandColor,
    ...Shadows.onFocusStateShadow({}, primaryBrandColor),
  },
  input: {
    caretColor: infoBrandColor,
    ':focus': { borderColor: infoBrandColor },
  },
  // styles that depend on `inline` prop
  ...inline ?
    StyleSheet.create({
      formContainer: {
        marginTop: 25,
        display: 'grid',
        gridTemplateColumns: '3fr 2fr',
        gridGap: 16,
      },
      phoneNumberInputContainer: {
        marginTop: 20,
      },
      buttonOverrides: {
        backgroundColor: primaryBrandColor,
        ...Shadows.onFocusStateShadow({}, primaryBrandColor),
      },
    }) :
    StyleSheet.create({
      formContainer: {},
      phoneNumberInputContainer: {
        marginTop: 45,
        marginBottom: 25,
      },
    }),
});
