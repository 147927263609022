import AddButton from '@root/quotes/src/components/coverage-options/add-button';
import CoverageGroupItem from '@root/quotes/src/components/coverage-options/coverage-group-item';
import ListCard, { ListCardItem } from '@root/core/src/components/list-card';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function AddCoverageCard({
  children, description, headerBackgroundColor, heading, IconComponent, isCoverageAdded, onAddCoverage, primaryBrandColor, renderRightQuoteBadge,
}) {
  if (isCoverageAdded) {
    return null;
  }

  const rightQuoteBadge = renderRightQuoteBadge && renderRightQuoteBadge();

  return (
    <>
      <ListCard headerBackgroundColor={headerBackgroundColor}>
        <CoverageGroupItem
          css={styles.listItemTop}
          description={description}
          heading={heading}
        >
          {children}
        </CoverageGroupItem>
        <ListCardItem css={styles.listItemBottom}>
          {rightQuoteBadge}
          <AddButton
            IconComponent={IconComponent}
            onClick={onAddCoverage}
            primaryBrandColor={primaryBrandColor}
            useRightQuoteIcon={!!rightQuoteBadge}
          >
            Add {heading}
          </AddButton>
        </ListCardItem>
      </ListCard>
    </>
  );
}

AddCoverageCard.propTypes = {
  children: PropTypes.node,
  description: PropTypes.node.isRequired,
  headerBackgroundColor: PropTypes.string,
  heading: PropTypes.node.isRequired,
  IconComponent: PropTypes.func,
  isCoverageAdded: PropTypes.bool.isRequired,
  onAddCoverage: PropTypes.func.isRequired,
  primaryBrandColor: PropTypes.string,
  renderRightQuoteBadge: PropTypes.func,
};

const styles = StyleSheet.create({
  listItemTop: {
    borderRadius: '10px 10px 0 0',
    ...Responsive.sm({
      border: `1px solid ${Colors.gray30()}`,
      borderBottom: 'none',
    }),
  },
  listItemBottom: {
    borderRadius: '0 0 10px 10px',
    border: `1px solid ${Colors.gray30()}`,
    background: Colors.nearWhite(),
  },
});
