import Button from '@root/core/src/components/button';
import IconCell from '@root/core/src/components/icon-cell';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import battery from '@root/quotes/src/assets/battery.svg';
import cone from '@root/quotes/src/assets/cone.svg';
import fuelCan from '@root/quotes/src/assets/fuel-can.svg';
import handKey from '@root/quotes/src/assets/hand-key.svg';
import tire from '@root/quotes/src/assets/tire.svg';
import towHook from '@root/quotes/src/assets/tow-hook.svg';
import towTruck from '@root/quotes/src/assets/tow-truck.png';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useMemo } from '@root/vendor/react';

export default function RoadsideLearnMoreModal({
  isShowing,
  onCancel,
  primaryBrandColor,
}) {
  const styles = useMemo(() => stylesGenerator({ primaryBrandColor }), [primaryBrandColor]);

  return (
    <Modal
      isShowing={isShowing}
      name={'roadside-learn-more'}
      onCancel={onCancel}
      styleOverrides={styles}
    >
      <div css={styles.headerContainer}>
        <img
          alt={'tow-truck'}
          css={styles.image}
          src={towTruck}
        />
        <h1 css={styles.primaryHeader}>Roadside Assistance</h1>
        <p css={styles.subheader}>Help when you need it</p>
      </div>
      <h2 css={styles.secondaryHeader}>Roadside assistance at your fingertips</h2>
      <p css={styles.paragraph}>You can request roadside assistance right through the Root app. We cover the costs for three incidents per 6-month policy term, up to $100 per incident.</p>
      <p css={styles.paragraph}>Here‘s what we cover:</p>

      <IconCell
        icon={towHook}
        primaryText={'Towing'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'A tow truck can transport your vehicle when it won’t start or is damaged and not safe to drive.'}
        secondaryTextStyles={styles.secondaryText}
      />
      <IconCell
        icon={battery}
        primaryText={'Jump-start'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'Left the lights on? No problem. A jump-start can get your battery going again.'}
        secondaryTextStyles={styles.secondaryText}
      />
      <IconCell
        icon={tire}
        primaryText={'Spare tire installation'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'When you‘ve got a flat, a spare tire can help get you back on the road.'}
        secondaryTextStyles={styles.secondaryText}
      />
      <IconCell
        icon={handKey}
        primaryText={'Lockout service'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'Assistance getting back into your vehicle when you‘ve locked yourself out.'}
        secondaryTextStyles={styles.secondaryText}
      />
      <IconCell
        icon={fuelCan}
        primaryText={'Fuel delivery'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'If you run out of gas, it‘s safe and easy to have gas brought to you.'}
        secondaryTextStyles={styles.secondaryText}
      />
      <IconCell
        icon={cone}
        primaryText={'Roadside winching'}
        primaryTextStyles={styles.primaryText}
        secondaryText={'When your vehicle gets stuck in sand or mud and you need to get it unstuck.'}
        secondaryTextStyles={styles.secondaryText}
      />

      <h2 css={styles.secondaryHeader}>Getting Reimbursed</h2>
      <p css={styles.paragraph}>We cover three incidents per 6-month policy term, up to $100 per incident. When you use our roadside partner, Root covers the first $100. If you choose to contact a different company, you can pay for the service up front and submit your receipt for reimbursement to: roadside@joinroot.com</p>
      <Button
        css={styles.button}
        onClick={onCancel}
        type={'button'}
      >
        {'Close'}
      </Button>
    </Modal>
  );
}

RoadsideLearnMoreModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  primaryBrandColor: PropTypes.string,
};

const stylesGenerator = ({ primaryBrandColor = Colors.rootOrange() }) => StyleSheet.create({
  button: {
    backgroundColor: primaryBrandColor,
    ...Shadows.onFocusStateShadow({}, primaryBrandColor),
  },
  cardWrapper: {
    marginTop: 105,
    height: 'calc(100% - 105px)',
    ...Responsive.sm({
      marginTop: 0,
      height: '100%',
    }),
  },
  outerContent: {
    maxHeight: 'calc(100vh - 105px)',
    ...Responsive.sm({
      maxHeight: '100vh',
    }),
  },
  primaryHeader: {
    ...Theme.heading2(),
    textAlign: 'center',
  },
  subheader: {
    ...Theme.heading4(),
    textAlign: 'center',
  },
  secondaryHeader: Theme.heading4(),
  paragraph: Theme.paragraph1(),
  innerContent: {
    padding: '0 !important',
  },
  primaryText: {
    ...Theme.heading4(),
  },
  secondaryText: {
    ...Theme.paragraph1(),
  },
  headerContainer: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: 20,
  },
  image: {
    width: 120,
    margin: '0 auto',
    marginBottom: 20,
  },
});
