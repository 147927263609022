import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import sortBy from '@root/vendor/lodash/sortBy';

export default function StateSelect({
  dropdownIndicator,
  dropdownIndicatorColor,
  errorLabel,
  inputId = 'address-level1',
  inputName = 'address-level1',
  name,
  onChange,
  optionStyles,
  persistLabel = false,
  placeholder = 'Choose state',
  selectBoxFocusedStyles,
  selectBoxStyles,
  selectedValue,
  shouldRenderNativeSelectError,
  value,
  wrapperStyle,
}) {
  return (
    <Select
      autoComplete={'shipping address-level1'}
      dropdownIndicator={dropdownIndicator}
      dropdownIndicatorColor={dropdownIndicatorColor}
      errorLabel={errorLabel}
      inputId={inputId}
      inputName={name ?? inputName}
      onChange={onChange}
      options={sortBy(Market.OPTIONS, ['label'])}
      optionStyles={optionStyles}
      persistLabel={persistLabel}
      placeholder={placeholder}
      selectBoxFocusedStyles={selectBoxFocusedStyles}
      selectBoxStyles={selectBoxStyles}
      selectedValue={value ?? selectedValue}
      shouldRenderNativeSelectError={shouldRenderNativeSelectError}
      wrapperStyles={wrapperStyle}
    />
  );
}

StateSelect.propTypes = {
  dropdownIndicator: PropTypes.string,
  dropdownIndicatorColor: PropTypes.string,
  errorLabel: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionStyles: PropTypes.object,
  persistLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
  selectedValue: PropTypes.string,
  shouldRenderNativeSelectError: PropTypes.bool,
  value: PropTypes.string,
  wrapperStyle: PropTypes.object,
};
