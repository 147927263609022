import BaseAddDriverManager from '@root/profile/src/components/add-driver-manager';
import Driver from '@root/auto-pricing/src/models/driver';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { createElement, useCallback, useState } from '@root/vendor/react';
import SubflowAnimator from '@root/profile/src/components/subflow-animator';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { StyleSheet } from '@root/core/src/utils/styles';

const AddDriverManager = ({
  chevronStyle,
  cssButtonOverrides,
  dropdownIndicatorColor,
  inputStyle,
  linkStyles,
  onCancel,
  onUpdateProfileParams,
  optionStyles,
  profileParams,
  profileRulesContext,
  selectBoxFocusedStyles,
  selectBoxStyles,
}) => {
  const [{ infoColor, primaryColor }] = useBranding();
  const [driver, setDriver] = useState(Driver.buildFromData({
    licenseState: profileParams.market(),
    selected: true,
  }));
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleStepFinished = useCallback((updatedDriver) => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex >= BaseAddDriverManager.addDriverSteps.length) {
      return onUpdateProfileParams(ProfileDriverService.addDriver(profileParams, updatedDriver));
    }

    setDriver(updatedDriver);
    setCurrentStepIndex(nextStepIndex);
  }, [currentStepIndex, onUpdateProfileParams, profileParams]);

  const CurrentStepComponent = useCallback((props) => {
    return (
      createElement(
        BaseAddDriverManager.addDriverSteps[currentStepIndex],
        props,
      )
    );
  }, [currentStepIndex]);

  return (
    <div css={styles.content}>
      <SubflowAnimator animatorKey={currentStepIndex.toString()}>
        <CurrentStepComponent
          brandedColor={primaryColor}
          brandInfoColor={infoColor}
          chevronStyle={chevronStyle}
          cssButtonOverrides={cssButtonOverrides}
          driver={driver}
          dropdownIndicatorColor={dropdownIndicatorColor}
          inputStyle={inputStyle}
          linkStyles={linkStyles}
          onCancel={onCancel}
          onDone={handleStepFinished}
          optionStyles={optionStyles}
          profileParams={profileParams}
          profileRulesContext={profileRulesContext}
          selectBoxFocusedStyles={selectBoxFocusedStyles}
          selectBoxStyles={selectBoxStyles}
        />
      </SubflowAnimator>
    </div>
  );
};

AddDriverManager.propTypes = {
  chevronStyle: PropTypes.string,
  cssButtonOverrides: PropTypes.object,
  dropdownIndicatorColor: PropTypes.string,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  optionStyles: PropTypes.object,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
};

const styles = StyleSheet.create({
  content: {
    minHeight: 510,
  },
});

export default AddDriverManager;
