import AppsflyerService from '@root/attribution/src/services/appsflyer-service';
import CaretLoader from '@root/core/src/components/caret-loader';
import LinkButton from '@root/core/src/components/link-button';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import useCreateDeeplinkUrl from '@root/triage/src/api/create-deeplink-url/use-create-deeplink-url';
import { useCallback, useState } from '@root/vendor/react';

export default function TriageGetAppButton({
  buttonText = 'Download the app',
  primaryBrandColor,
  trackClick,
}) {
  const [deeplinkUrl, setDeeplinkUrl] = useState();

  const shouldDisableButton = ['local', 'partner-testing', 'staging'].includes(environment.environment);

  const isCreating = useCreateDeeplinkUrl(useCallback((result) => {
    if (result.isSuccess()) {
      setDeeplinkUrl(result.data.deeplink);
    } else {
      setDeeplinkUrl(AppsflyerService.getAppsflyerLinkWithParameters());
    }
  }, []));

  if (isCreating) {
    return <CaretLoader />;
  }

  const handleClick = () => {
    if (shouldDisableButton) {
      alert('Note: This button only works on production, so it has been disabled.');
      return;
    }

    trackClick('GET_APP');
  };

  return (
    <LinkButton
      href={shouldDisableButton ? undefined : deeplinkUrl}
      onClick={handleClick}
      primaryBrandColor={primaryBrandColor}
      rel={'noopener'}
    >
      {buttonText}
    </LinkButton>
  );
}

TriageGetAppButton.propTypes = {
  buttonText: PropTypes.string,
  primaryBrandColor: PropTypes.string,
  trackClick: PropTypes.func.isRequired,
};
