import Carousel from '@root/core/src/components/carousel';
import Link from '@root/core/src/components/link';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

const PipAndCoverageStackingExplanationLinkModal = ({
  infoBrandColor,
  primaryBrandColor,
  secondaryBrandColor,
  showStackingSlide,
}) => {
  const [isShowing, setIsShowing] = useState(false);

  const styles = useMemo(() => stylesGenerator({ infoBrandColor }), [infoBrandColor]);

  const PipSlide = () => (
    <>
      <h2 css={styles.title}>What is personal injury protection?</h2>
      <p css={[styles.paragraph, styles.spacing]}>Personal injury protection helps cover expenses after an accident, regardless of who is at fault. It includes:</p>
      <ul>
        <li css={styles.paragraph}><strong>Medical Loss:</strong> Covers medical expenses like hospital bills, doctor visits, recovery, and necessary medical treatments.</li>
        <li css={styles.paragraph}><strong>Essential services:</strong> Covers work loss, replacement services (like childcare or home maintenance), and funeral costs.</li>
      </ul>
      <p css={styles.paragraph}>Each person involved in an accident is covered up to the limits you select for each category.</p>
    </>
  );

  const StackedCoverageSlide = () => (
    <>
      <h2 css={styles.title}>What does stacked coverage mean?</h2>
      <p css={styles.paragraph}>Stacked coverage gives you more financial protection by combining the coverage limits from multiple vehicles on your policy.</p>
      <p css={[styles.paragraph, styles.spacing]}><strong>For example,</strong> if your selected limit is $1,000 per person:</p>
      <ul>
        <li css={styles.paragraph}><strong>Without stacking:</strong> Each person is covered up to $1,000.</li>
        <li css={styles.paragraph}><strong>With stacking:</strong> Your total coverage increases with each additional vehicle.</li>
        <ul>
          <li css={styles.paragraph}>2 vehicles: $2,000 per person.</li>
          <li css={styles.paragraph}>3 vehicles: $3,000 per person.</li>
        </ul>
      </ul>
      <p css={styles.paragraph}>Stacking ensures you have more coverage available if you’re in a serious accident.</p>
    </>
  );

  const children = [<PipSlide key={'pip'} />];

  if (showStackingSlide) {
    children.push(<StackedCoverageSlide key={'stacked'} />);
  }

  return (
    <>
      <Link
        cssOverrides={styles.link}
        onClick={() => setIsShowing(true)}
      >
        Learn more
      </Link>
      <Modal
        isShowing={isShowing}
        name={'learn-more-modal'}
        onCancel={() => setIsShowing(false)}
        styleOverrides={modalStyleOverrides}
      >
        <Carousel
          onContinue={() => setIsShowing(false)}
          primaryBrandColor={primaryBrandColor}
          secondaryBrandColor={secondaryBrandColor}
        >
          {children}
        </Carousel>
      </Modal>
    </>
  );
};

PipAndCoverageStackingExplanationLinkModal.propTypes = {
  infoBrandColor: PropTypes.string,
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
  showStackingSlide: PropTypes.bool,
};

export default PipAndCoverageStackingExplanationLinkModal;

const stylesGenerator = ({ infoBrandColor = Colors.rootOrange() }) => StyleSheet.create({
  link: {
    ...Theme.linkRootOrange(),
    color: infoBrandColor,
    ':hover': {
      color: Colors.toRgba(infoBrandColor, 0.8),
    },
  },
  title: {
    ...Theme.heading2(),
  },
  paragraph: {
    ...Theme.paragraph1(),
  },
  spacing: {
    marginBottom: 20,
  },
});

const modalStyleOverrides = {
  outerContent: {
    padding: '36px 24px 24px',
    ...Responsive.sm({
      maxHeight: '95vh',
      height: 'auto',
      maxWidth: 550,
      padding: 32,
    }),
  },
  innerContent: {
    display: 'flex',
    maxWidth: '100%',
    height: '100%',
    padding: 0,
    ...Responsive.sm({
      marginTop: 48,
      padding: 0,
    }),
  },
};
