/* eslint react/jsx-no-bind: 0 */
import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindParams from '@root/quotes/src/models/bind-params';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import Money from '@root/core/src/models/money';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React, { Component } from '@root/vendor/react';

export default class CoverageSectionAdditional extends Component {
  static propTypes = {
    bindParams: PropTypes.instanceOf(BindParams).isRequired,
    cardHeaderBackgroundColor: PropTypes.string,
    coverageSelections: PropTypes.object,
    customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
    dropdownIndicator: PropTypes.string,
    matchedQuoteEnabled: PropTypes.bool,
    mobileSelectedOptionStyles: PropTypes.object,
    onAcceptCoverages: PropTypes.func.isRequired,
    onCoverageChange: PropTypes.func.isRequired,
    onCoverageSelectClose: PropTypes.func.isRequired,
    onCoverageSelectOpen: PropTypes.func.isRequired,
    onDeclineCoverages: PropTypes.func.isRequired,
    onTortChange: PropTypes.func.isRequired,
    onVehicleToggle: PropTypes.func.isRequired,
    optionStyles: PropTypes.object,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
    secondaryBrandColor: PropTypes.string,
    selectBoxFocusedStyles: PropTypes.object,
    selectBoxStyles: PropTypes.object,
    styleOverrides: PropTypes.object,
    tortPrices: PropTypes.shape({
      [BillingCycle.MONTHLY]: PropTypes.shape({
        full: PropTypes.number.isRequired,
        limited: PropTypes.number.isRequired,
      }),
      [BillingCycle.FULL_TERM]: PropTypes.shape({
        full: PropTypes.number.isRequired,
        limited: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  _getPriceString(price) {
    const priceString = Money.fromCents(Math.abs(price)).formattedDollars();

    if (price < 0) {
      return `Saves ${priceString}`;
    } else {
      return `Adds ${priceString}`;
    }
  }

  _onTortChange(value) {
    if (value !== this.props.bindParams.tortSelection) {
      this.props.onTortChange(value);
    }
  }

  render() {
    const {
      bindParams: { billingCycle, tortSelection },
      dropdownIndicator,
      matchedQuoteEnabled,
      mobileSelectedOptionStyles,
      onCoverageSelectClose,
      onCoverageSelectOpen,
      optionStyles,
      quotesContext: { manualTortMarket },
      tortPrices,
      secondaryBrandColor,
      selectBoxFocusedStyles,
      selectBoxStyles,
      styleOverrides,
    } = this.props;

    if (!manualTortMarket) {
      return <div />;
    }

    const fullTortPrice = tortPrices[billingCycle]['full'];
    const limitedTortPrice = tortPrices[billingCycle]['limited'];

    const currentSelectedTortPrice = tortPrices[billingCycle][tortSelection];

    const subLabel = (selection, price) => {
      return tortSelection === selection ? 'Selected' : this._getPriceString(price - currentSelectedTortPrice);
    };

    const tortOptions = [
      {
        label: 'Full Tort',
        subLabel: subLabel('full', fullTortPrice),
        value: 'full',
      },
      {
        label: 'Limited Tort',
        subLabel: subLabel('limited', limitedTortPrice),
        value: 'limited',
      },
    ];

    return (
      <CoverageGroup
        description={''}
        heading={'Additional Selections'}
        styleOverrides={styleOverrides}
      >
        <CoverageSelectionCard
          description={
            'With Limited Tort coverage, you give up the right to sue someone for causing you pain and suffering if you’re injured in an accident. With Full Tort coverage, you can sue someone for causing you pain and suffering if that person is at fault for the accident.'
          }
          headerBackgroundColor={this.props.cardHeaderBackgroundColor}
          heading={'Tort Selection'}
          isCoverageAdded={true}
          matchedQuoteEnabled={matchedQuoteEnabled}
          vehiclesSelected={true}
        >
          <QuotesSelect
            dropdownIndicator={dropdownIndicator}
            dropdownIndicatorColor={secondaryBrandColor}
            inputId={'tort-coverage-select'}
            inputName={'tort-coverage-select'}
            mobileSelectedOptionStyles={mobileSelectedOptionStyles}
            onChange={(value) => this._onTortChange(value)}
            onClose={onCoverageSelectClose}
            onOpen={onCoverageSelectOpen}
            options={tortOptions}
            optionStyles={optionStyles}
            placeholder={'Select option'}
            selectBoxFocusedStyles={selectBoxFocusedStyles}
            selectBoxStyles={selectBoxStyles}
            selectedValue={tortSelection}
          />
        </CoverageSelectionCard>
      </CoverageGroup>
    );
  }
}
