import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Coverage from '@root/auto-pricing/src/models/coverage';
import QuoteStrings from '@root/quotes/src/models/quote-strings';
import { RootError } from '@root-common/root-errors';

const QUOTE_SORT_ORDER = {
  [QuoteStrings.TIERS.CUSTOM]: 0,
  [QuoteStrings.TIERS.RIGHT_QUOTE]: 0.5,
  [QuoteStrings.TIERS.LOW_RIGHT_QUOTE]: 0.6,
  [QuoteStrings.TIERS.STATE_MINIMUM]: 1,
  [QuoteStrings.TIERS.LOW]: 2,
  [QuoteStrings.TIERS.RECOMMENDED]: 3,
  [QuoteStrings.TIERS.HIGH]: 4,
};

export default class Quote {
  static build({
    coverages = [],
    fullTermPayment,
    id,
    monthlyTermPayments = [],
    originQuoteId,
    premiumRatios,
    rateId,
    tier,
  } = {}) {
    const buildParams = {
      coverages: coverages.map(Coverage.build),
      fullTermPayment,
      id,
      monthlyTermPayments,
      originQuoteId,
      premiumRatios,
      rateId,
      tier,
    };

    return Object.assign(
      new Quote(),
      buildParams,
    );
  }

  get sortValue() {
    return QUOTE_SORT_ORDER[this.tier];
  }

  get isRightQuote() {
    return this.tier === QuoteStrings.TIERS.RIGHT_QUOTE;
  }

  get isLowRightQuote() {
    return this.tier === QuoteStrings.TIERS.LOW_RIGHT_QUOTE;
  }

  getFullTermPriceInDollars() {
    return this.fullTermPayment.totalAmountInCents / 100;
  }

  getCoverage(coverageSymbol) {
    return this.coverages.find((c) => c.symbol === coverageSymbol);
  }

  getCoverages(coverageSymbols = []) {
    return this.coverages.filter((c) => coverageSymbols.includes(c.symbol));
  }

  getCoveragesExcluding(coverageSymbols = []) {
    return this.coverages.filter((c) => !coverageSymbols.includes(c.symbol));
  }

  getTotalPriceIfPaidMonthly() {
    return this.monthlyTermPayments.reduce(
      (accum, { totalAmountInCents }) => accum + totalAmountInCents, 0
    );
  }

  getFullTermSavingsCents() {
    return this.getTotalPriceIfPaidMonthly() - this.fullTermPayment.totalAmountInCents;
  }

  getFullTermSavingsDollars(precise = false) {
    const savingsInDollars = this.getFullTermSavingsCents() / 100;
    return precise ? savingsInDollars : Math.floor(savingsInDollars);
  }

  getQuoteDescription(market, quotes = [], noQuoteTitle, isSelectedSummaryCard) {
    if (this.isRightQuote || isSelectedSummaryCard && noQuoteTitle) {
      return QuoteStrings.GENERIC_QUOTE_DESCRIPTION;
    }

    if (this.isLowRightQuote) {
      return QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.LOW];
    }

    let description = Object.prototype.hasOwnProperty.call(QuoteStrings.DESCRIPTION_BY_MARKET_BY_TIER, market) ?
      QuoteStrings.DESCRIPTION_BY_MARKET_BY_TIER[market][this.tier] :
      QuoteStrings.DESCRIPTION_BY_TIER[this.tier];

    if (this.tier === QuoteStrings.TIERS.CUSTOM && quotes) {
      const originQuote = quotes.find((q) => q.id === this.originQuoteId);
      const originQuoteTitle = this._getQuoteTitleByMarketByTier(market, originQuote?.tier);

      if (originQuoteTitle) {
        description = `You customized your ${originQuoteTitle} quote.`;
      }
    }

    if (description) {
      return description;
    }

    throw new RootError({
      message: `Unexpected quote tier: ${this.tier}`,
      name: 'QuoteError',
      fingerprint: ['QuoteUnexpectedTier'],
    });
  }

  getFirstPaymentFeeTotalInCents() {
    if (this.monthlyTermPayments && this.monthlyTermPayments[0]) {
      return this.monthlyTermPayments[0].charges.filter((charge) => charge.isFee)
        .reduce((total, charge) => total += charge.amountInCents, 0);
    }
    return 0;
  }

  getQuoteTitle(market, quotes = [], isSelectedSummaryCard = false, noQuoteTitle = false, fallbackToGenericQuoteTitle = true) {
    let title = this._getQuoteTitleByMarketByTier(market, this.tier);

    if (quotes && (this.isRightQuote || this.isLowRightQuote || this.tier === QuoteStrings.TIERS.CUSTOM)) {
      const originQuote = quotes.find((q) => q.id === this.originQuoteId);
      const originQuoteTitle = this._getQuoteTitleByMarketByTier(market, originQuote?.tier);

      if (originQuoteTitle) {
        if (!this.isRightQuote && !this.isLowRightQuote) {
          title = `${originQuoteTitle}—Custom`;
        } else {
          title = originQuoteTitle;
        }
      }
    }

    if (isSelectedSummaryCard && noQuoteTitle) {
      return fallbackToGenericQuoteTitle ? QuoteStrings.GENERIC_QUOTE_TITLE : null;
    }

    if (title) {
      return title;
    } else if (this.isRightQuote || this.isLowRightQuote) {
      return fallbackToGenericQuoteTitle ? QuoteStrings.GENERIC_QUOTE_TITLE : null;
    }

    throw new RootError({
      message: `Unexpected quote tier: ${this.tier}`,
      name: 'QuoteError',
      fingerprint: ['QuoteUnexpectedTier'],
    });
  }

  getOrderedCoveragesBySymbols(symbols) {
    return symbols
      .filter((symbol) => this.coverages.some((coverage) => symbol === coverage.symbol))
      .map((symbol) => this.coverages.find((c) => symbol === c.symbol));
  }

  getTierForAnalytics() {
    return this.tier.toUpperCase();
  }

  hasDifferingFirstPayment() {
    return this.monthlyTermPayments[0].totalAmountInCents !== this.monthlyTermPayments[1].totalAmountInCents;
  }

  getMonthlyAmountInCents() {
    return this.monthlyTermPayments[1].totalAmountInCents;
  }

  getMonthlyOrFullTermPaymentInCents(billingCycle) {
    return this.getMonthlyOrFullTermPaymentPremium(billingCycle).totalAmountInCents;
  }

  getMonthlyOrFullTermPaymentPremium(billingCycle) {
    if (billingCycle === BillingCycle.MONTHLY) {
      return this.monthlyTermPayments[1];
    } else if (billingCycle === BillingCycle.FULL_TERM) {
      return this.fullTermPayment;
    } else {
      throw `Unsupported billingCycle: ${billingCycle}`;
    }
  }

  getFirstMonthOrFullTermPaymentPremium(billingCycle) {
    if (billingCycle === BillingCycle.MONTHLY) {
      return this.monthlyTermPayments[0].charges;
    } else if (billingCycle === BillingCycle.FULL_TERM) {
      return this.fullTermPayment.charges;
    }
  }

  getDueTodayInCents(billingCycle) {
    if (billingCycle === BillingCycle.MONTHLY) {
      return this.monthlyTermPayments[0].totalAmountInCents;
    } else if (billingCycle === BillingCycle.FULL_TERM) {
      return this.fullTermPayment.totalAmountInCents;
    } else {
      throw `Unsupported billingCycle: ${billingCycle}`;
    }
  }

  getMonthlyPricesInCents() {
    return this.monthlyTermPayments.map((payment) => payment.totalAmountInCents);
  }

  hasExtraCharges(billingCycle) {
    const payments = billingCycle === BillingCycle.MONTHLY ? this.monthlyTermPayments : [this.fullTermPayment];

    return payments.some((payment) => {
      const nonZeroCharges = payment.charges.filter((charge) => charge.amountInCents > 0);
      return nonZeroCharges.length > 1;
    });
  }

  withRepaymentFromPreviousPolicy({ totalUncollectedAmountInCents }) {
    if (this._includesRepaymentCharge()) {
      return this;
    }

    return Object.assign(
      new Quote(),
      this,
      {
        monthlyTermPayments: this.monthlyTermPayments.map((payment, index) => {
          if (index === 0) {
            return this._bundleRepaymentChargesIntoPayment(payment, totalUncollectedAmountInCents);
          } else {
            return payment;
          }
        }),
        fullTermPayment: this._bundleRepaymentChargesIntoPayment(this.fullTermPayment, totalUncollectedAmountInCents),
      },
    );
  }

  _includesRepaymentCharge() {
    return this.fullTermPayment.charges.some((charge) => charge.name === QuoteStrings.NON_PAY_CANCEL_REPAYMENT.CHARGE_NAME);
  }

  _bundleRepaymentChargesIntoPayment(payment, totalUncollectedAmountInCents) {
    const repaymentCharge = {
      name: QuoteStrings.NON_PAY_CANCEL_REPAYMENT.CHARGE_NAME,
      description: QuoteStrings.NON_PAY_CANCEL_REPAYMENT.CHARGE_DESCRIPTION,
      amountInCents: totalUncollectedAmountInCents,
      isFee: false,
    };

    return {
      ...payment,
      totalAmountInCents: payment.totalAmountInCents + totalUncollectedAmountInCents,
      charges: [...payment.charges, repaymentCharge],
    };
  }

  _getQuoteTitleByMarketByTier(market, tier) {
    return Object.prototype.hasOwnProperty.call(QuoteStrings.TITLE_BY_MARKET_BY_TIER, market) ?
      QuoteStrings.TITLE_BY_MARKET_BY_TIER[market][tier] :
      QuoteStrings.TITLE_BY_TIER[tier];
  }
}
