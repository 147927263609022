import AttributionService, { AttributionActions } from '@root/attribution/src/services/attribution-service';
import AuthService from '@root/core/src/services/auth-service';
import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindParams from '@root/quotes/src/models/bind-params';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import BraintreeService from '@root/payments/src/services/braintree';
import Card from '@root/core/src/components/card';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ErrorSummary from '@root/core/src/components/error-summary';
import Link from '@root/core/src/components/link';
import Money from '@root/core/src/models/money';
import PaymentBreakdownModal from '@root/payments/src/components/payment-breakdown-modal';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCheckoutReviewBillingDetails from '@root/quotes/src/components/quote-checkout-review-billing-details';
import QuoteReviewCard from '@root/quotes/src/components/quote-review-card';
import QuotesCheckoutError from '@root/quotes/src/models/quotes-checkout-error';
import QuotesCheckoutForm from '@root/quotes/src/models/quote-checkout-form';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesService from '@root/quotes/src/services/quotes-service';
import React, { useCallback, useEffect, useMemo, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SceneLoader from '@root/core/src/components/scene-loader';
import SecurityFooter from '@root/core/src/components/security-footer';
import md5 from '@root/vendor/md5';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import useCreatePaymentAuthorizationMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-create-payment-authorization-mutation';
import { BraintreeForm } from '@root/quotes/src/components/braintree';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const billingCycles = BillingCycle.getDisplayOptions();
const BindParameterName = {
  BILLING_CYCLE: 'billingCycle',
  PAYMENT_NONCE: 'paymentNonce',
  START_DATE: 'startDate',
};
const CheckoutFieldName = {
  BILLING_CYCLE: 'billingCycle',
  START_DATE: 'startDate',
};

const BindCheckout = ({
  bindParams,
  braintreeService,
  onEditPlan,
  onPolicyCreated,
  maxPolicyStartDate,
  profileParams,
  quotesContext,
  selectedQuote,
  today,
  updateBindParams,
}) => {
  const {
    billingCycle,
    startDate,
    tortSelection,
  } = bindParams;

  const isMounted = useRef(false);
  const { trackClick, trackEvent } = useAnalytics('BIND_CHECKOUT');
  const [branding] = useBranding();
  const brandedStyles = stylesGenerator(branding);
  const strings = useI18nNext('pages.bindCheckout');

  const braintreeServiceRef = useRef(braintreeService || new BraintreeService());
  const [checkoutError, setCheckoutError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showQuoteCheckoutPaymentBreakdownModal, setShowQuoteCheckoutPaymentBreakdownModal] = useState(false);

  const [form, updateForm] = useState(() => new QuotesCheckoutForm().setAttributes({
    billingCycle,
    startDate,
  }));

  const amountDueTodayInCents = selectedQuote.getDueTodayInCents(form.billingCycle);
  const subsequentMonthlyPaymentInCents = selectedQuote.getMonthlyOrFullTermPaymentInCents(form.billingCycle);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  const {
    isLoading: isCreatingPaymentAuthorization,
    isSuccess: didCreatePaymentAuthorization,
    mutate: createPaymentAuthorization,
  } = useCreatePaymentAuthorizationMutation({
    onError: () => {
      setCheckoutError(QuotesCheckoutError.ERRORS.default);
    },
    onSuccess: async (token) => {
      const authResult = await braintreeServiceRef.current.authorize(token);
      if (authResult.isError()) {
        handleBraintreeError();
      }
    },
  });

  useEffect(() => {
    if (isMounted.current && !isCreatingPaymentAuthorization && !didCreatePaymentAuthorization && !braintreeServiceRef.current.client) {
      createPaymentAuthorization();
    }
  }, [didCreatePaymentAuthorization, isCreatingPaymentAuthorization, createPaymentAuthorization]);

  const handleBillingCycleSelectOpen = useCallback(() => {
    trackEvent('PAYMENT_FREQUENCY_PRESSED');
  }, [trackEvent]);

  const handleUpdateBillingCycle = useCallback((newBillingCycle) => {
    updateBindParams((prevBindParams) => prevBindParams.set(BindParameterName.BILLING_CYCLE, newBillingCycle));
    updateForm((prevForm) => prevForm.set(CheckoutFieldName.BILLING_CYCLE, newBillingCycle));
  }, [updateBindParams, updateForm]);

  const handleUpdateStartDate = useCallback((newStartDate) => {
    updateBindParams((prevBindParams) => prevBindParams.set(BindParameterName.START_DATE, newStartDate));
    updateForm((prevForm) => prevForm.set(CheckoutFieldName.START_DATE, newStartDate));
  }, [updateBindParams, updateForm]);

  const updateFormAndErrors = useCallback((fieldName, value) => {
    updateForm((prevForm) => {
      const updatedForm = prevForm.set(fieldName, value);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [fieldName]: updatedForm.errorsFor(fieldName),
      }));
      return updatedForm;
    });
  }, []);

  const handleBraintreeError = useCallback((error = QuotesCheckoutError.ERRORS.default) => {
    trackEvent('BRAINTREE_ERROR', error);
    setCheckoutError(error);
    setIsSubmitting(false);
  }, [trackEvent]);

  const handleBraintreeSuccess = useCallback(async (paymentNonce) => {
    const result = await QuotesService.createPolicy(bindParams.set(BindParameterName.PAYMENT_NONCE, paymentNonce).serializeForSubmission());

    if (result.isSuccess) {
      braintreeServiceRef.current.teardown();
      AttributionService.trackAttributionAction(AttributionActions.BIND);

      let conversionValue = selectedQuote.fullTermPayment.totalAmountInCents;
      if (billingCycle === BillingCycle.MONTHLY) {
        conversionValue = selectedQuote.monthlyTermPayments.reduce((accumulator, currentValue) => accumulator + currentValue.totalAmountInCents, 0);
      }

      const {
        email, accountId,
      } = AuthService.getCurrentUserContext();
      ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.BIND_CONVERSION, {
        id: selectedQuote.id,
        conversionValue,
        billingCycle,
        quoteId: selectedQuote.id,
        hashedEmail: md5(email || ''),
        accountId,
        mailingAddressZip: profileParams?.mailingAddress?.zip,
        event: ConversionTrackingService.CONVERSION_EVENTS.BIND_CONVERSION,
      });
      onPolicyCreated(result);
    } else {
      handleBraintreeError(result.error);
    }
  }, [billingCycle, bindParams, handleBraintreeError, onPolicyCreated, profileParams, selectedQuote.fullTermPayment.totalAmountInCents, selectedQuote.id, selectedQuote.monthlyTermPayments]);

  const handleFieldChange = useCallback((fieldName) => (value) => {
    updateFormAndErrors(fieldName, value);
    if (fieldName === CheckoutFieldName.BILLING_CYCLE) {
      trackEvent(['PAYMENT_FREQUENCY_PRESSED', value]);
      handleUpdateBillingCycle(value);
    }

    if (fieldName === CheckoutFieldName.START_DATE) {
      trackEvent('START_DATE_PRESSED');
      handleUpdateStartDate(value);
    }
  }, [handleUpdateBillingCycle, handleUpdateStartDate, trackEvent, updateFormAndErrors]);

  const handlePaymentFieldsValidityChanged = useCallback((valid) => {
    updateFormAndErrors('paymentFieldsValid', valid);
  }, [updateFormAndErrors]);

  const handleSubmit = useCallback(() => {
    trackClick('SUBMIT');
    datadogRum.addAction('embedded_checkout_submit_clicked');

    const isValidForm = form.isValid();

    setIsSubmitting(isValidForm);
    setCheckoutError(null);
    if (!isValidForm) {
      trackEvent('INVALID_FORM', form.errors());
    }

    // If these errors are empty & the Braintree client is authorized, a policy will be created via an effect
    setFormErrors(form.errors());
  }, [form, trackClick, trackEvent]);

  const BraintreeFormHeader = useMemo(() => {
    return (
      <h2 css={styles.subheading}>
        {strings.paymentMethodFormHeader}
      </h2>
    );
  }, [strings.paymentMethodFormHeader]);

  const renderError = (errorToRender) => {
    if (!errorToRender) { return null; }
    return (
      <ErrorSummary
        heading={errorToRender.heading}
        message={errorToRender.message}
      />
    );
  };

  const renderNextPaymentDueSection = useCallback(() => {
    if (billingCycle !== BillingCycle.MONTHLY) {
      return null;
    }

    return (
      <div css={styles.nextPaymentDue}>
        <p>
          {strings.nextPaymentDue}
        </p>
        <p>
          {Money.fromCents(subsequentMonthlyPaymentInCents).formattedDollarsAndCents()}
        </p>
      </div>
    );
  }, [billingCycle, subsequentMonthlyPaymentInCents, strings]);

  const _renderPaymentExplainerLink = (quote) => {
    let initialPaymentFeeTotal = 0;
    const sumUpFeesFn = (charge) => {
      if (charge.isFee) {
        initialPaymentFeeTotal += charge.amountInCents;
      }
    };

    quote.getFirstMonthOrFullTermPaymentPremium(form.billingCycle).forEach(sumUpFeesFn);

    if (initialPaymentFeeTotal <= 0) { return; }

    return (
      <div css={styles.paymentExplainerText}>
        Total includes premium plus {Money.fromCents(initialPaymentFeeTotal).format()} in fees.&nbsp;
        <Link
          css={styles.paymentExplainerLink}
          cssOverrides={brandedStyles.infoLinkStyles}
          onClick={() => setShowQuoteCheckoutPaymentBreakdownModal(true)}
        >
          See breakdown
        </Link>
      </div>
    );
  };

  const hasFormFieldErrors = Object.keys(formErrors).every((key) => { return formErrors[key]; });
  return (
    <>
      <BindView.ExitFlowButton />
      <BindView >
        <BindView.Card
          cssOverrides={
            {
              ...Responsive.md({
                width: Responsive.BREAKPOINTS.md,
                paddingTop: 10,
                marginTop: 50,
                paddingRight: 40,
                paddingLeft: 40,
              }),
            }
          }
        >
          {!braintreeServiceRef.current.client && isCreatingPaymentAuthorization && <SceneLoader hideHeader={true} />}
          {!braintreeServiceRef.current.client && !isCreatingPaymentAuthorization && renderError(QuotesCheckoutError.ERRORS.default)}
          {braintreeServiceRef.current.client &&
            <div css={styles.container}>
              <h1 css={[styles.header, styles.mobileHeader]}>{strings.reviewYourPlan}</h1>
              <div css={styles.quoteReviewCardContainer}>
                <QuoteReviewCard
                  headerOverrideStyles={brandedStyles.quoteOverviewHeader}
                  linkStyles={brandedStyles.linkStyles}
                  manualTortMarket={quotesContext.manualTortMarket}
                  mobileLinkStyles={brandedStyles.mobileLinkStyles}
                  onEditPlan={onEditPlan}
                  profileParams={profileParams}
                  quote={selectedQuote}
                  tortSelection={tortSelection}
                />
              </div>
              <div>
                <Card cssOverrides={styles.quoteCheckoutReviewCardStyles}>
                  <div css={styles.quoteCheckoutReviewCardInternal}>
                    <QuoteCheckoutReviewBillingDetails
                      billingCycles={billingCycles}
                      bindParams={bindParams}
                      brandedColor={branding.secondaryColor}
                      form={form}
                      handleFieldChange={handleFieldChange}
                      maxDate={maxPolicyStartDate}
                      onBillingSelectCycleOpen={handleBillingCycleSelectOpen}
                      optionStyles={brandedStyles.optionStyles}
                      quotesPrice={amountDueTodayInCents}
                      selectedDayStyles={branding.secondaryColor ? brandedStyles.selectedDayStyles : null}
                      shouldRenderNativeMobile={false}
                      startDateAppendedText={strings.startDate.appendedText}
                      today={today}
                    />
                    {_renderPaymentExplainerLink(selectedQuote)}
                    <BraintreeForm
                      skipTeardown
                      bindParams={bindParams}
                      braintreeService={braintreeServiceRef.current}
                      disableSubmit={hasFormFieldErrors}
                      form={form}
                      formHeader={BraintreeFormHeader}
                      infoBrandColor={branding.infoColor}
                      isSubmitting={isSubmitting}
                      onBraintreeHostedFieldsError={handleBraintreeError}
                      onBraintreeSuccess={handleBraintreeSuccess}
                      onBraintreeTokenizeError={handleBraintreeError}
                      onPaymentFieldsValidityChanged={handlePaymentFieldsValidityChanged}
                      onSubmit={handleSubmit}
                      selectedQuote={selectedQuote}
                      submitButtonStyleOverrides={brandedStyles.submitButton}
                    />
                    {renderError(checkoutError)}
                    {renderNextPaymentDueSection()}
                    <SecurityFooter text={strings.secure} />
                    <p css={styles.paymentDisclaimer}>{strings.paymentDisclaimer}</p>
                  </div>
                </Card>
              </div>
            </div>
          }
          <PaymentBreakdownModal
            closeButtonOverrides={{ backgroundColor: branding.primaryColor }}
            isModalOpen={showQuoteCheckoutPaymentBreakdownModal}
            onClose={() => setShowQuoteCheckoutPaymentBreakdownModal(false)}
            paymentBreakdown={selectedQuote.getFirstMonthOrFullTermPaymentPremium(form.billingCycle)}
          />
        </BindView.Card>
      </BindView>
    </>
  );
};
export default BindCheckout;

BindCheckout.propTypes = {
  bindParams: PropTypes.instanceOf(BindParams),
  braintreeService: PropTypes.object,
  maxPolicyStartDate: PropTypes.instanceOf(Date).isRequired,
  onEditPlan: PropTypes.func.isRequired,
  onPolicyCreated: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams),
  quotesContext: PropTypes.instanceOf(QuotesContext),
  selectedQuote: PropTypes.instanceOf(CustomQuote).isRequired,
  today: PropTypes.instanceOf(Date),
  updateBindParams: PropTypes.func.isRequired,
};

const stylesGenerator = ({
  primaryColor, infoColor,
}) => StyleSheet.create({
  quoteOverviewHeader: {
    backgroundColor: primaryColor,
  },
  optionStyles: {
    ':hover, :hover > span': {
      background: Colors.gray20(),
      color: Colors.black(),
    },
  },
  selectedDayStyles: {
    ':hover': {
      backgroundColor: primaryColor,
    },
    ':not(.disabled):not(.outside):not(:past)': {
      backgroundColor: primaryColor,
      color: Colors.white(),
    },
  },
  submitButton: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  linkStyles: {
    ':hover': {
      color: Colors.toRgba(Colors.white(), 0.8),
    },
  },
  mobileLinkStyles: {
    color: primaryColor,
    ':hover': {
      color: primaryColor,
    },
  },
  infoLinkStyles: {
    color: `${infoColor} !important`,
    ':hover': {
      color: `${Colors.toRgba(infoColor, 0.8)} !important`,
    },
  },
});

const styles = StyleSheet.create({
  header: {
    ...Theme.heading1(),
    marginBottom: 20,
  },
  quoteReviewCardContainer: {
    width: '60%',
    ...Responsive.lessThanMd({
      width: '100%',
    }),
  },
  quoteCheckoutReviewCardStyles: {
    alignItems: 'center',
    ...Responsive.md({
      ...Shadows.none(),
      padding: 0,
      paddingLeft: 50,
      background: Colors.white(),
    }),
  },
  container: {
    padding: '20px 20px',
    marginBottom: 70,
    background: Colors.white(),
    minHeight: 'calc(100vh - 63px)',
    height: '100%',
    ...Responsive.md({
      padding: '50px 10px',
      marginBottom: 0,
    }),
    display: 'flex',
    justifyContent: 'center',
    ...Responsive.lessThanMd({
      justifyContent: 'flex-start',
      flexDirection: 'column',
    }),
  },
  subheading: {
    ...Theme.heading3(),
    marginTop: 24,
    marginBottom: 20,
  },
  nextPaymentDue: {
    ...Theme.paragraph1(),
    color: Colors.gray50(),
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  paymentDisclaimer: {
    ...Theme.paragraph2(),
    marginTop: 16,
  },
  quoteCheckoutReviewCardInternal: {
    maxWidth: 500,
    ...Responsive.lessThanMd({
      maxWidth: 'none',
      width: '100%',
    }),
  },
  mobileHeader: {
    display: 'none',
    ...Responsive.lessThanMd({
      display: 'block',
    }),
  },
  paymentExplainerLink: {
    ...Theme.paragraph2(),
    color: Colors.black(),
  },
  paymentExplainerText: {
    color: Colors.gray60(),
    ...Theme.paragraph2(),
  },
});
