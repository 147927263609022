import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';

export default function MaritalStatusSelect({
  dropdownIndicator,
  dropdownIndicatorColor,
  onBlur,
  onChange,
  options,
  optionStyles,
  persistLabel = false,
  selectBoxFocusedStyles,
  selectBoxStyles,
  selectedValue,
}) {
  const mappedOptions = options.map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Select
      autoComplete={'marital-status-level1'}
      dropdownIndicator={dropdownIndicator}
      dropdownIndicatorColor={dropdownIndicatorColor}
      inputId={'marital-status-level1'}
      inputName={'marital-status-level1'}
      onBlur={onBlur}
      onChange={onChange}
      options={mappedOptions}
      optionStyles={optionStyles}
      persistLabel={persistLabel}
      placeholder={'Marital status'}
      selectBoxFocusedStyles={selectBoxFocusedStyles}
      selectBoxStyles={selectBoxStyles}
      selectedValue={selectedValue}
    />
  );
}

MaritalStatusSelect.propTypes = {
  dropdownIndicator: PropTypes.string,
  dropdownIndicatorColor: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  optionStyles: PropTypes.object,
  persistLabel: PropTypes.bool,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
  selectedValue: PropTypes.string.isRequired,
};
