import CoverageGroupItem from '@root/quotes/src/components/coverage-options/coverage-group-item';
import ListCard from '@root/core/src/components/list-card';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RemoveButton from '@root/quotes/src/components/coverage-options/remove-button';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function CoverageSelectionCard({
  additionalDescription,
  children,
  description,
  headerBackgroundColor,
  heading,
  isCoverageAdded = false,
  onRemoveCoverage,
  renderLearnMore,
  vehiclesSelected = false,
}) {
  if (!isCoverageAdded || !vehiclesSelected) {
    return null;
  }

  const renderedHeading = !onRemoveCoverage
    ? heading
    : (
      <div css={styles.heading}>
        {heading}
        <RemoveButton onClick={onRemoveCoverage} />
      </div>
    );

  return (
    <>
      <ListCard headerBackgroundColor={headerBackgroundColor}>
        <CoverageGroupItem
          additionalDescription={additionalDescription}
          css={styles.listItemTop}
          description={description}
          heading={renderedHeading}
        >
          {renderLearnMore && renderLearnMore()}
        </CoverageGroupItem>
        {children}
      </ListCard>
    </>
  );
}

CoverageSelectionCard.propTypes = {
  additionalDescription: PropTypes.string,
  children: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  headerBackgroundColor: PropTypes.string,
  heading: PropTypes.node.isRequired,
  isCoverageAdded: PropTypes.bool,
  onRemoveCoverage: PropTypes.func,
  renderLearnMore: PropTypes.func,
  vehiclesSelected: PropTypes.bool,
};

const styles = StyleSheet.create({
  listItemTop: {
    borderRadius: '10px 10px 0 0',
    ...Responsive.sm({
      border: `1px solid ${Colors.gray30()}`,
      borderBottom: 'none',
    }),
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
