import GaragingAddressSection from '@root/profile/src/components/garaging-address/garaging-address-section';
import Input, { TextTypes } from '@root/core/src/components/input';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileModalButton from '@root/profile/src/components/profile-modal-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import UpdateGaragingAddress from '@root/profile-review/src/components/update-garaging-address'; // eslint-disable-line root/no-circular-dependencies
import Vehicle from '@root/core/src/models/vehicle';
import VehicleCreationParams from '@root/auto-pricing/src/models/vehicle-creation-params';
import Vin from '@root/core/src/models/vin';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import vinFront from '@root/core/src/assets/images/vin-front.png';
import vinSide from '@root/core/src/assets/images/vin-side.png';
import { Colors, StyleSheet, Theme, styled } from '@root/core/src/utils/styles';

export default function ProfileAddVehicleVin(props) {
  const { trackClick } = useAnalytics(`${props.module}_PROFILE_ADD_VEHICLE_VIN`);

  const [vin, setVin] = useState('');
  const [error, setError] = useState('');
  const [showGaragingAddressModal, setShowGaragingAddressModal] = useState(false);
  const [workingGaragingAddress, setWorkingGaragingAddress] = useState();

  function _handleChangeInput(value) {
    setVin(value.toUpperCase());
  }

  function _handleCancel() {
    trackClick('CANCEL');
    props.onCancel();
  }

  function _handleSubmit() {
    trackClick('SUBMIT');
    const { isValid, error: errorMessage } = Vin.validate(vin, props.vehicles);

    if (isValid) {
      props.onDone(props.vehicleCreationParams.set('vin', vin));
    } else {
      setError(errorMessage);
    }
  }

  const handleGaragingAddressPressed = () => {
    trackClick('GARAGING_ADDRESS');

    setShowGaragingAddressModal(true);
  };

  const handleGaragingAddressModalOnDone = (address) => {
    setShowGaragingAddressModal(false);
    setWorkingGaragingAddress(address);
  };

  const _renderGaragingAddress = () => {
    if (props.garagingAddressEnabled) {
      if (workingGaragingAddress?.garagingAddress1) {
        props.vehicleCreationParams.set('garagingAddress1', workingGaragingAddress.garagingAddress1);
        props.vehicleCreationParams.set('garagingAddress2', workingGaragingAddress.garagingAddress2);
        props.vehicleCreationParams.set('garagingCity', workingGaragingAddress.garagingCity);
        props.vehicleCreationParams.set('garagingState', workingGaragingAddress.garagingState);
        props.vehicleCreationParams.set('garagingZip', workingGaragingAddress.garagingZip);
      } else if (!props.vehicleCreationParams.garagingAddress1) {
        props.vehicleCreationParams.set('garagingAddress1', props.profileParams.mailingAddress.line1);
        props.vehicleCreationParams.set('garagingAddress2', props.profileParams.mailingAddress.line2);
        props.vehicleCreationParams.set('garagingCity', props.profileParams.mailingAddress.city);
        props.vehicleCreationParams.set('garagingState', props.profileParams.mailingAddress.state);
        props.vehicleCreationParams.set('garagingZip', props.profileParams.mailingAddress.zip);
      }

      const garagingAddress = {
        garagingAddress1: props.vehicleCreationParams.garagingAddress1,
        garagingAddress2: props.vehicleCreationParams.garagingAddress2,
        garagingCity: props.vehicleCreationParams.garagingCity,
        garagingState: props.vehicleCreationParams.garagingState,
        garagingZip: props.vehicleCreationParams.garagingZip,
      };

      return (
        <GaragingAddressSection
          onPress={() => handleGaragingAddressPressed()}
          workingGaragingAddress={garagingAddress}
        />
      );
    }
  };

  return (
    <>
      {!showGaragingAddressModal && (
        <div>
          <ProfileHeading>
            What’s the VIN?
          </ProfileHeading>
          <ProfileForm onSubmit={_handleSubmit}>
            <Input
              focusOnMount
              errorLabel={error}
              inputStyle={props.inputStyle}
              inputType={TextTypes.TEXT}
              label={'VIN'}
              onChange={_handleChangeInput}
              value={vin}
            />
            <section css={styles.content}>
              <p>Don’t have the Vehicle Identification Number (VIN) handy? Here’s where to find it.</p>
              <article css={styles.vinRow}>
                <div css={styles.vinImgContainer}>
                  <VinMarker
                    color={props.brandedColor || Colors.rootOrange()}
                    xPos={52}
                    yPos={10}
                  />
                  <img src={vinFront} />
                </div>
                <div css={styles.vinContentContainer}>
                  <h4>Driver side dashboard</h4>
                  <p>Just look through the windshield.</p>
                </div>
              </article>
              <article css={styles.vinRow}>
                <div css={styles.vinImgContainer}>
                  <VinMarker
                    color={props.brandedColor || Colors.rootOrange()}
                    xPos={42}
                    yPos={45}
                  />
                  <img src={vinSide} />
                </div>
                <div css={styles.vinContentContainer}>
                  <h4>Driver side door jamb</h4>
                  <p>Look for a sticker with lots of numbers and letters.</p>
                </div>
              </article>
            </section>
            {_renderGaragingAddress()}
            <ProfileModalButton
              cssButtonOverrides={props.cssButtonOverrides}
              disabled={vin.length !== Vin.LENGTH}
            >
              {'Add vehicle'}
            </ProfileModalButton>
            <NevermindLink
              linkStyles={props.linkStyles}
              onCancel={_handleCancel}
            />
          </ProfileForm>
        </div>
      )}
      {showGaragingAddressModal &&
        <UpdateGaragingAddress
          module={`${props.module}`}
          onDone={handleGaragingAddressModalOnDone}
          profileParams={props.profileParams}
          workingGaragingAddress={workingGaragingAddress}
        />
      }
    </>
  );
}

ProfileAddVehicleVin.propTypes = {
  brandedColor: PropTypes.string,
  cssButtonOverrides: PropTypes.object,
  garagingAddressEnabled: PropTypes.bool,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  module: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  profileParams: PropTypes.object,
  vehicleCreationParams: PropTypes.instanceOf(VehicleCreationParams).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.instanceOf(Vehicle)).isRequired,
};

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    marginTop: 30,
    ...Theme.paragraph1(),
  },
  vinRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  vinImgContainer: {
    position: 'relative',

    '& img': {
      width: 160,
      height: 'auto',
    },
  },
  vinContentContainer: {
    '& h4': {
      marginBottom: 4,
      color: Colors.black(),
    },
  },
});

const VinMarker = styled('div')`
  --vin-marker-color: ${(props) => props.color || Colors.rootOrange()};

  position: absolute;
  top: ${(props) => props.yPos}%;
  left: ${(props) => props.xPos}%;
  width: 30px;
  height: 30px;
  border-radius: 999px;
  border: 1.5px solid var(--vin-marker-color);
  background-image: radial-gradient(
    circle at center,
    hsl(from var(--vin-marker-color) H S L / 0.5) 10%,
    hsl(from var(--vin-marker-color) H S L / 0.05) 100%
  );

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 3.5px;
    border-radius: 1px;
    background-color: white;
    box-shadow: 0 0 8px 1px var(--vin-marker-color);
  }
`;
