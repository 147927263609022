import AuthService from '@root/core/src/services/auth-service';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Button from '@root/bind.joinroot.com/src/components/button';
import CheckFilledListItem from '@root/bind.joinroot.com/src/components/check-filled-list-item';
import InsuranceCard, { CARD_SCALE_YX } from '@root/bind.joinroot.com/src/components/insurance-card';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Separator from '@root/core/src/components/separator';
import TriageGetApp from '@root/triage/src/components/triage-get-app';
import phoneImage from '@root/bind.joinroot.com/src/assets/phone-with-stars.png';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { AutoscalingSvg } from '@root/bind.joinroot.com/src/components/icons';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { regular } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { useConfig } from '@root/bind.joinroot.com/src/context/config';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const cardFlipDelay = 3200;

const BindCoveredEntry = ({ profileParams, softLaunchExperience }) => {
  const { trackClick } = useAnalytics('BIND_COVERED_ENTRY');
  const strings = useI18nNext('pages.bindCoveredEntry');
  const partnerName = useI18nNext('partner');
  const [{
    infoColor,
    primaryColor,
    shouldReturnToPartner,
  }] = useBranding();
  const bodyStrings = softLaunchExperience ? strings.softLaunch : strings.v1;
  const downloadAppStyles = useMemo(() => stylesGenerator({ primaryBrandColor: primaryColor }), [primaryColor]);

  const {
    drivers,
  } = profileParams;

  const {
    email,
  } = AuthService.getCurrentUserContext();

  const pniDriver = drivers?.find(({ pni }) => pni);
  const firstName = pniDriver?.firstName;
  const lastName = pniDriver?.lastName;

  const { successUrl } = useConfig();

  const [cardFlipped, setCardFlipped] = useState(false);
  const cardContainerRef = useRef(null);
  const [cardContainerWidth, setCardContainerWidth] = useState(undefined);
  const cardHeight = useMemo(() => cardContainerWidth ? cardContainerWidth * CARD_SCALE_YX : undefined, [cardContainerWidth]);

  useEffect(() => {
    const flipTimeout = setTimeout(() => setCardFlipped(true), cardFlipDelay);
    return () => {
      clearTimeout(flipTimeout);
    };
  }, [setCardFlipped]);

  useLayoutEffect(() => {
    if (cardContainerRef.current === null) {
      return;
    }

    setCardContainerWidth(cardContainerRef.current.clientWidth);
  }, [cardContainerRef, setCardContainerWidth]);

  const returnToVehicleOrderSuccess = () => {
    trackClick('RETURN_TO_VEHICLE_ORDER_SUCCESS');
    // eslint-disable-next-line root/prevent-use-of-window-location
    window.location.assign(successUrl);
  };

  if (!shouldReturnToPartner) {
    return (
      <BindView
        fullWhite
      >
        <div css={downloadAppStyles.container}>
          <div css={downloadAppStyles.imageContainer}>
            <img
              css={downloadAppStyles.image}
              src={phoneImage}
            />
          </div>
          <div css={downloadAppStyles.contentContainer}>
            <h1 css={downloadAppStyles.header}>{'Congrats! You have amazing insurance.'}</h1>
            <h2 css={downloadAppStyles.subheader}>{'Here\'s what\'s next.'}</h2>
            <h2>Download the app</h2>
            <p css={downloadAppStyles.grayText}>You can access your insurance card, manage payments, file claims, request roadside assistance, and more.</p>
            <div css={downloadAppStyles.downloadAppButton}>
              <TriageGetApp
                formButtonText={'Text download link'}
                infoBrandColor={infoColor}
                inline={true}
                primaryBrandColor={primaryColor}
                trackClick={trackClick}
              />
            </div>
            <Separator />
            <h2>Check your email</h2>
            <div css={downloadAppStyles.grayText}>
              <span>We’ve sent you an email to </span><span css={downloadAppStyles.boldParagraph}>{email}</span><span> with:</span>
              <ul>
                <li>{bodyStrings.instructionsBulletOne}</li>
                <li>{bodyStrings.instructionsBulletTwo}</li>
                <li>{bodyStrings.instructionsBulletThree}</li>
                {softLaunchExperience &&
                <li>{bodyStrings.instructionsBulletFour}</li>
                }
              </ul>
            </div>
          </div>
        </div>
      </BindView>
    );
  }

  return (
    <BindView
      fullWhite
    >
      <div css={styles.container}>
        <div
          css={styles.leftContainer}
          ref={cardContainerRef}
        >
          {cardContainerWidth && cardHeight &&
            <>
              <InsuranceCard
                cssOverrides={styles.insuranceCard}
                firstName={firstName}
                flipped={!softLaunchExperience && cardFlipped}
                height={cardHeight}
                lastName={lastName}
                partnerName={partnerName}
                width={cardContainerWidth}
              />
              <AutoscalingSvg
                css={styles.backgroundCircle}
                height={cardContainerWidth}
                viewBox={'0 0 80 80'}
                width={cardContainerWidth}
              >
                <circle
                  cx={'40'}
                  cy={'40'}
                  fill={Colors.lightBlue()}
                  r={'37'}
                />
              </AutoscalingSvg>
            </>
          }
        </div>
        <div css={styles.rightContainer}>
          <h2>{strings.title}</h2>
          <div>
            <CheckFilledListItem
              complete
              text={bodyStrings.checkFilledOne}
            />
            <CheckFilledListItem
              complete={!softLaunchExperience}
              text={bodyStrings.checkFilledTwo}
            />
          </div>
          <Separator />
          <div css={styles.grayText}>
            {bodyStrings.instructionsHeader}
            <ul>
              <li>{bodyStrings.instructionsBulletOne}</li>
              <li>{bodyStrings.instructionsBulletTwo}</li>
              <li>{bodyStrings.instructionsBulletThree}</li>
              {softLaunchExperience &&
                <li>{bodyStrings.instructionsBulletFour}</li>
              }
            </ul>
          </div>
        </div>
      </div>
      <div css={styles.container}>
        <Button
          css={styles.button}
          onClick={returnToVehicleOrderSuccess}
        >
          {strings.button}
        </Button>
      </div>
    </BindView>
  );
};

const stylesGenerator = ({ primaryBrandColor = Colors.rootOrange() }) => StyleSheet.create({
  boldParagraph: {
    fontWeight: 'bold',
  },
  container: {
    padding: '40px 20px',
    width: 'min(1200px, 100%)',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    alignItems: 'center',
    ...Responsive.lg({
      flexDirection: 'row',
    }),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: 'min(600px, 100%)',
    height: 'auto',
  },
  contentContainer: {
    maxWidth: 550,
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    ...Responsive.lg({
      fontSize: 57,
      lineHeight: '64px',
    }),
  },
  subheader: {
    color: primaryBrandColor,
    fontSize: 28,
    lightHeight: '36px',
    ...Responsive.lg({
      fontSize: 32,
      lineHeight: '40px',
    }),
  },
  grayText: {
    color: Colors.gray50(),
    ...regular(),
  },
  downloadAppButton: {
    marginTop: -20,
    marginBottom: -40,
  },
});

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: '4rem',
    width: Responsive.BREAKPOINTS.lg,
    ...Responsive.md({
      flexDirection: 'row',
      width: Responsive.BREAKPOINTS.md,
    }),
    ...Responsive.lessThanMd({
      width: '100%',
    }),
  },
  leftContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ...Responsive.lessThanMd({
      marginBottom: '20vw',
      width: '80%',
    }),
  },
  rightContainer: {
    flex: 3,
    ...Responsive.lessThanMd({
      paddingLeft: 0,
    }),
    paddingLeft: '3rem',
  },
  grayText: {
    color: Colors.gray50(),
    ...regular(),
  },
  insuranceCard: {
    display: 'flex',
    flex: 1,
    width: '100%',
    zIndex: 2,
  },
  backgroundCircle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    marginTop: '-22%',
  },
  button: {
    width: '50%',
    ...Responsive.lessThanMd({
      width: '80%',
    }),
  },
});

BindCoveredEntry.propTypes = {
  profileParams: PropTypes.instanceOf(ProfileParams),
  softLaunchExperience: PropTypes.bool,
};

export default BindCoveredEntry;
