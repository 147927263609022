import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import CloseModalButton from '@root/bind.joinroot.com/src/components/close-modal-button';
import EditDriverManager from '@root/bind.joinroot.com/src/components/bind-edit-driver/edit-driver-manager';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import useDeepLink from '@root/bind.joinroot.com/src/context/deep-link';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_CONTEXT = 'BIND_EDIT_DRIVER';
export const ANALYTICS_EVENTS = {
  CLOSE_MODAL_BUTTON: 'CLOSE_MODAL_BUTTON',
};

const BindEditDriver = ({
  onCancel,
  onUpdateProfileParams,
  profileParams,
  profileRules,
  universalDriverId,
}) => {
  const { trackClick, trackEvent } = useAnalytics(ANALYTICS_CONTEXT);

  const [useBrandingState] = useBranding();
  const deepLink = useDeepLink();
  const brandStyles = useMemo(() => stylesGenerator(useBrandingState), [useBrandingState]);

  const handleModalClose = () => {
    trackClick(ANALYTICS_EVENTS.CLOSE_MODAL_BUTTON);
    onCancel();
  };

  const isPniDriver = useMemo(
    () => profileParams?.primaryNamedInsured?.universalDriverId === universalDriverId,
    [profileParams?.primaryNamedInsured, universalDriverId]
  );

  const onProfileChange = useCallback((updatedProfileParams) => {
    onUpdateProfileParams(updatedProfileParams);
  }, [onUpdateProfileParams]);

  useEffect(
    () => deepLink.wasDeepLinked() && isPniDriver && onCancel(),
    [deepLink, isPniDriver, onCancel]
  );

  return (
    <>
      {deepLink.wasDeepLinked() ?
        <BindView.ExitFlowButton exitToQuote />
        :
        <BindView.BackToProfileButton onClick={onCancel} />
      }
      <BindView>
        <BindView.Card>
          <CloseModalButton onClick={handleModalClose} />
          <EditDriverManager
            chevronStyle={useBrandingState.chevronDown}
            circleSelectedStyles={brandStyles.toggleSelectedCircleStyle}
            cssButtonOverrides={brandStyles.buttonStyle}
            deepLinked={deepLink.wasDeepLinked()}
            dropdownIndicatorColor={useBrandingState.secondaryColor}
            inputStyle={brandStyles.inputStyle}
            isOriginalDriver={profileParams?.originalUniversalDriverId === universalDriverId}
            linkStyles={brandStyles.linkStyles}
            onProfileChange={onProfileChange}
            optionStyles={brandStyles.optionStyles}
            pniUniversalDriverId={profileParams.getPniDriver().universalDriverId}
            profileParams={profileParams}
            profileRulesContext={profileRules}
            selectBoxFocusedStyles={brandStyles.selectBoxFocusedStyles}
            selectBoxStyles={brandStyles.selectBoxStyles}
            toggleSelectedStyles={brandStyles.toggleSelectedStyle}
            trackClick={trackClick}
            trackEvent={trackEvent}
            universalDriverId={universalDriverId}
          />
        </BindView.Card>
      </BindView>
    </>
  );
};

const stylesGenerator = ({
  infoColor, primaryColor, secondaryColor,
}) => StyleSheet.create({
  buttonStyle: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  toggleSelectedStyle: {
    background: secondaryColor,
  },
  toggleSelectedCircleStyle: {
    right: 0,
    borderColor: secondaryColor,
  },
  inputStyle: {
    caretColor: infoColor,
    ':focus': {
      borderColor: infoColor,
    },
  },
  linkStyles: {
    color: secondaryColor,
    ':hover': {
      color: secondaryColor,
    },
  },
  optionStyles: {
    ':hover, :hover > span': {
      background: Colors.gray20(),
      color: Colors.black(),
    },
  },
  selectBoxStyles: {
    ':focus': {
      borderColor: infoColor,
    },
  },
  selectBoxFocusedStyles: {
    borderColor: infoColor,
  },
});

BindEditDriver.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  profileParams: PropTypes.object.isRequired,
  profileRules: PropTypes.object.isRequired,
  universalDriverId: PropTypes.string.isRequired,
};

export default BindEditDriver;
