import BindParams from '@root/quotes/src/models/bind-params';
import ErrorReportService from '@root/core/src/services/error-report-service';
import LoaderButton from '@root/core/src/components/loader-button';
import Money from '@root/core/src/models/money';
import PaymentFormElements from '@root/payments/src/components/payment-form-elements';
import PropTypes from '@root/vendor/prop-types';
import Quote from '@root/quotes/src/models/quote';
import QuotesPrice from '@root/quotes/src/components/quotes-price';
import QuotesStickyFooter from '@root/quotes/src/components/quotes-sticky-footer';
import React, { useCallback } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

export function BraintreeForm({
  bindParams,
  braintreeService,
  disableSubmit,
  form,
  formHeader,
  infoBrandColor,
  isSubmitting,
  onBraintreeHostedFieldsError,
  onBraintreeSuccess,
  onBraintreeTokenizeError,
  onPaymentFieldsValidityChanged,
  onSubmit,
  selectedQuote,
  skipTeardown = false,
  submitButtonStyleOverrides,
}) {
  const analyticsContext = 'QUOTES_CHECKOUT_FORM';

  const { trackClick, trackEvent } = useAnalytics(analyticsContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    trackClick(analyticsContext, 'SUBMITTED');
    onSubmit();

    trackEvent('BRAINTREE_TOKENIZE');
    const result = await braintreeService.tokenize();
    if (result.isSuccess()) {
      if (!skipTeardown) {
        await braintreeService.teardown();
      }
      onBraintreeSuccess(result.nonce);
    } else {
      ErrorReportService.reportError({
        caughtAt: 'braintree-form',
        error: result.error,
        additionalData: result.flattenedDetails(),
      });
      onBraintreeTokenizeError();
    }
  };

  // Create a function 1 time to call the onBraintreeHostedFieldsError without a parameter.
  // PaymentFormElements will call the onInitializationError function with a parameter, but
  // the consumers of braintree-form are counting on no parameters being provided, so they
  // can use defaults.
  const handleInitializationError = useCallback(() => {
    onBraintreeHostedFieldsError();
  }, [onBraintreeHostedFieldsError]);

  const footerPrice = Money.fromCents(selectedQuote.getMonthlyOrFullTermPaymentInCents(bindParams.billingCycle));

  return (
    <form onSubmit={handleSubmit}>
      {formHeader}
      <PaymentFormElements
        braintreeService={braintreeService}
        infoBrandColor={infoBrandColor}
        maskCardNumberInput={false}
        onInitializationError={handleInitializationError}
        onValidityChange={onPaymentFieldsValidityChanged}
      />
      <LoaderButton
        disabled={disableSubmit}
        isLoading={isSubmitting}
        loadingText={'Processing...'}
        styles={[
          styles.formButton,
          submitButtonStyleOverrides,
        ]}
      > {'Submit\xa0payment'}
      </LoaderButton>
      <QuotesStickyFooter
        bindParams={bindParams}
        footerStyles={styles.cta}
        quote={selectedQuote}
      >
        <QuotesPrice
          billingCycle={form.billingCycle}
          price={footerPrice}
          size={QuotesPrice.Size.SMALL}
        />
        <LoaderButton
          disabled={disableSubmit}
          isLoading={isSubmitting}
          loadingText={'Processing...'}
          styles={[
            styles.ctaButton,
            submitButtonStyleOverrides,
          ]}
        > {'Submit\xa0payment'}
        </LoaderButton>
      </QuotesStickyFooter>
    </form>
  );
}

BraintreeForm.propTypes = {
  bindParams: PropTypes.instanceOf(BindParams).isRequired,
  braintreeService: PropTypes.object.isRequired,
  /**
   * @type {bool} true to disable submit button.
   * The consumer is responsible to send props to disable it when necessary, as this component is
   * unaware of other potential fields that may impact the availability of the submit button.
   */
  disableSubmit: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  formHeader: PropTypes.node.isRequired,
  infoBrandColor: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onBraintreeHostedFieldsError: PropTypes.func.isRequired,
  onBraintreeSuccess: PropTypes.func.isRequired,
  onBraintreeTokenizeError: PropTypes.func.isRequired,
  /**
   * @type {func} called with a boolean where true means payment fields are valid.
   */
  onPaymentFieldsValidityChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedQuote: PropTypes.instanceOf(Quote).isRequired,
  skipTeardown: PropTypes.bool,
  submitButtonStyleOverrides: PropTypes.object,
};

const styles = StyleSheet.create({
  ctaButton: {
    width: 160,
    height: 40,
    margin: 0,
  },
  formButton: {
    display: 'none',
    marginTop: 36,
    ...Responsive.sm({
      display: 'block',
    }),
  },
  cta: {
    ...Responsive.sm({
      display: 'none',
    }),
  },
  row: {
    marginTop: 10,
  },
  smallRow: {
    marginTop: 10,
    ...Responsive.sm({
      marginTop: 0,
    }),
  },
});
