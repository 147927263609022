import Input, { TextTypes } from '@root/core/src/components/input';
import MaskedInput from '@root/vendor/react-text-mask';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { PHONE_NUMBER_MASK, cleanPhoneNumberValue } from '@root/core/src/models/phone';
import { StyleSheet } from '@root/core/src/utils/styles';

export function PhoneNumberInput({
  autoFocus = true,
  disabled = false,
  errorLabel,
  icon,
  inputId,
  inputMask,
  inputRef,
  inputStyle,
  label,
  onBlur,
  onChange,
  onFocus,
  value,
  wrapperStyle,
}) {
  const handleChange = (newValue) => {
    const cleanedPhoneNumber = cleanPhoneNumberValue(newValue);
    onChange(cleanedPhoneNumber);
  };

  const handleBlur = (v) => onBlur && onBlur(v);
  const handleFocus = (v) => onFocus?.(v);

  const wrapperStyles = [styles.wrapper, wrapperStyle];

  const renderInput = (ref, props) => {
    const attachRefs = (el) => {
      ref(el);

      if (typeof inputRef === 'function') { inputRef(el); } else if (inputRef) { inputRef.current = el; }
    };

    return (
      <Input
        autoFocus={autoFocus}
        disabled={disabled}
        icon={icon}
        inputType={TextTypes.TEL}
        label={label}
        ref={attachRefs}
        {...props}
      />
    );
  };

  return (
    <div css={wrapperStyles}>
      <MaskedInput
        errorLabel={errorLabel}
        id={inputId}
        inputStyle={inputStyle}
        mask={inputMask}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        render={renderInput}
        type={TextTypes.TEL}
        value={value || ''}
      />
    </div>
  );
}

PhoneNumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string,
  icon: PropTypes.node,
  inputId: PropTypes.string,
  inputMask: PropTypes.array,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PhoneNumberInput.defaultProps = {
  inputMask: PHONE_NUMBER_MASK,
};

export default React.forwardRef((props, ref) => (
  <PhoneNumberInput
    {...props}
    inputRef={ref}
  />
));

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: 65,
    position: 'relative',
  },
});
