import FieldCell from '@root/profile/src/components/edit-driver/field-cell';
import FieldGroup from '@root/profile/src/components/edit-driver/field-group';
import FieldHeading from '@root/profile/src/components/edit-driver/field-heading';
import MaritalStatusSelect from '@root/profile/src/components/marital-status-select';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function AdditionalInfoFields({
  chevronStyle, dropdownIndicatorColor, maritalStatus, maritalStatusOptions, onChange, optionStyles, persistLabel = false, selectBoxFocusedStyles, selectBoxStyles,
}) {
  return (
    <FieldGroup>
      <FieldHeading>
        Additional info
      </FieldHeading>
      <FieldCell>
        <MaritalStatusSelect
          dropdownIndicator={chevronStyle}
          dropdownIndicatorColor={dropdownIndicatorColor}
          onChange={onChange('maritalStatus')}
          options={maritalStatusOptions}
          optionStyles={optionStyles}
          persistLabel={persistLabel}
          selectBoxFocusedStyles={selectBoxFocusedStyles}
          selectBoxStyles={selectBoxStyles}
          selectedValue={maritalStatus || ''}
        />
      </FieldCell>
    </FieldGroup>
  );
}

AdditionalInfoFields.propTypes = {
  chevronStyle: PropTypes.string,
  dropdownIndicatorColor: PropTypes.string,
  maritalStatus: PropTypes.string,
  maritalStatusOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  optionStyles: PropTypes.object,
  persistLabel: PropTypes.bool,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
};
