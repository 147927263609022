import Button from '@root/core/src/components/button';
import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';

export default function LinkButton({
  children,
  disabled = false,
  href = '#',
  primaryBrandColor,
  rel,
  ...props
}) {
  const styles = useMemo(() => stylesGenerator({ primaryBrandColor }), [primaryBrandColor]);

  return (
    <Button
      css={styles.buttonOverrides}
      {...props}
      passProps={{
        href: disabled ? undefined : href,
        rel,
      }}
      tag={'a'}
    >
      {children}
    </Button>
  );
}

const stylesGenerator = ({ primaryBrandColor = Colors.rootOrange() }) => ({
  buttonOverrides: {
    background: primaryBrandColor,
    ...Shadows.onFocusStateShadow({}, primaryBrandColor),
  },
});

LinkButton.propTypes = {
  ...Button.propTypes,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  primaryBrandColor: PropTypes.string,
  rel: PropTypes.string,
};
