import CoverageGroupItem from '@root/quotes/src/components/coverage-options/coverage-group-item';
import ListCard from '@root/core/src/components/list-card';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Vehicle from '@root/core/src/models/vehicle';
import VehicleToggle from '@root/quotes/src/components/coverage-options/coverage-vehicle-toggle';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function VehicleSelectionCard({
  description,
  disabled = false,
  isCoverageAdded = false,
  onToggle,
  selectedVins,
  vehicles,
  additionalDescription,
  toggleSelectedColor,
}) {
  const divider = <div css={styles.divider} />;

  if (!isCoverageAdded) {
    return divider;
  }

  return (
    <ListCard>
      <CoverageGroupItem
        additionalDescription={additionalDescription}
        css={styles.listItem}
        description={description}
      >
        <div css={styles.vehiclesContainer}>
          {vehicles.map((vehicle) => (
            <VehicleToggle
              disabled={disabled}
              key={vehicle.cid}
              onToggle={onToggle}
              selected={selectedVins.includes(vehicle.getAvailableVin())}
              toggleSelectedColor={toggleSelectedColor}
              vehicle={vehicle}
            />)
          )}
        </div>
        {divider}
      </CoverageGroupItem>
    </ListCard>
  );
}

VehicleSelectionCard.propTypes = {
  additionalDescription: PropTypes.string,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCoverageAdded: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  selectedVins: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSelectedColor: PropTypes.string,
  vehicles: PropTypes.arrayOf(PropTypes.instanceOf(Vehicle)).isRequired,
};

const styles = StyleSheet.create({
  listItem: {
    padding: 0,
  },
  vehiclesContainer: {
    margin: '20px 0',
  },
  divider: {
    backgroundColor: Colors.gray30(),
    height: 1,
    maxWidth: 550,
    marginBottom: 30,
  },
});
