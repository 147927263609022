import Button from '@root/core/src/components/button';
import ConfirmationDialog from '@root/profile-review/src/components/confirmation-dialog';
import DobDate from '@root/core/src/models/dob-date';
import EditDriverAdditionalInfoFields from '@root/profile/src/components/edit-driver/additional-info-fields';
import EditDriverDOBFields from '@root/profile/src/components/edit-driver/dob-fields';
import EditDriverLicenseInfoFields from '@root/profile/src/components/edit-driver/license-info-fields';
import EditDriverNameFields from '@root/profile/src/components/edit-driver/name-fields';
import LicenseValidationService from '@root/core/src/services/license-number-validation-service';
import Link from '@root/core/src/components/link';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileDriverStateSpecificRequirements from '@root/profile/src/components/profile-driver-state-specific-requirements';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import ToggleField from '@root/profile/src/components/edit-driver/toggle-field';
import driverFieldValidator from '@root/profile/src/utils/driver-field-validator';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_EVENTS = {
  REMOVE_DRIVER_CANCEL: 'REMOVE_DRIVER_CANCEL',
  REMOVE_DRIVER_CONFIRM: 'REMOVE_DRIVER_CONFIRM',
  REMOVE_DRIVER: 'REMOVE_DRIVER',
  SUBMIT: 'SUBMIT',
  TOGGLED_PNI: 'TOGGLED_PNI',
};

class EditDriverManager extends Component {
  static propTypes = {
    chevronStyle: PropTypes.string,
    circleSelectedStyles: PropTypes.object,
    cssButtonOverrides: PropTypes.object,
    deepLinked: PropTypes.bool.isRequired,
    disableFixedWidths: PropTypes.bool,
    dropdownIndicatorColor: PropTypes.string,
    inputStyle: PropTypes.object,
    isOriginalDriver: PropTypes.bool,
    linkStyles: PropTypes.object,
    onProfileChange: PropTypes.func.isRequired,
    optionStyles: PropTypes.object,
    pniUniversalDriverId: PropTypes.string.isRequired,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
    selectBoxFocusedStyles: PropTypes.object,
    selectBoxStyles: PropTypes.object,
    toggleSelectedStyles: PropTypes.object,
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    universalDriverId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isConfirmationShowing: false,
      isPni: props.pniUniversalDriverId === props.universalDriverId,
      profileParams: props.profileParams,
    };
  }

  componentDidMount() {
    const driver = this._getDriver();

    const dobError = driverFieldValidator(driver, 'dob');

    if (dobError) {
      this.setState({
        errors: {
          dob: dobError,
        },
      });
    }

    if (this.props.profileRulesContext.inexperiencedOperator) {
      this.setState((prevState) => ({
        profileParams: ProfileDriverService.setDriverValue(
          prevState.profileParams,
          this.props.universalDriverId,
          'yearsLicensedSelection',
          null
        ),
      }));
    }
  }

  _handleBlur = (field) => () => {
    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: driverFieldValidator(ProfileDriverService.getDriver(prevState.profileParams, this.props.universalDriverId), field),
      },
    }));
  };

  _handleLicenseChange = (value) => {
    this.setState((prevState) => {
      const driver = ProfileDriverService.getDriver(prevState.profileParams, this.props.universalDriverId);
      const dob = driver.dob.isObfuscated()
        ? new DobDate()
        : driver.dob;

      let profileParams = ProfileDriverService
        .setDriverValue(prevState.profileParams, this.props.universalDriverId, 'licenseNumber', value);
      profileParams = ProfileDriverService
        .setDriverValue(profileParams, this.props.universalDriverId, 'dob', dob);

      return {
        profileParams,
      };
    });
  };

  _handleChange = (field) => (value) => {
    this.setState((prevState) => ({
      profileParams: ProfileDriverService.setDriverValue(prevState.profileParams, this.props.universalDriverId, field, value),
    }));
  };

  _handleToggle = (field) => () => {
    this.setState((prevState) => ({
      profileParams: ProfileDriverService.toggleDriverValue(prevState.profileParams, this.props.universalDriverId, field),
    }));
  };

  _handlePNIToggle = () => {
    const {
      pniUniversalDriverId,
      trackEvent,
      universalDriverId,
    } = this.props;
    const { isPni } = this.state;

    trackEvent(ANALYTICS_EVENTS.TOGGLED_PNI, {
      selected: !isPni,
      universalDriverId,
    });

    const newPniUniversalDriverId = isPni ? pniUniversalDriverId : universalDriverId;
    this.setState((prevState) => ({
      isPni: !isPni,
      profileParams: ProfileDriverService.togglePNI(prevState.profileParams, newPniUniversalDriverId),
    }));
  };

  _handleRemoveDriver = () => {
    this.props.trackClick(ANALYTICS_EVENTS.REMOVE_DRIVER);

    if (!this.state.isConfirmationShowing) {
      this.setState({
        isConfirmationShowing: true,
      });
    }
  }

  _handleRemoveCancel = () => {
    this.props.trackClick(ANALYTICS_EVENTS.REMOVE_DRIVER_CANCEL);

    this.setState({
      isConfirmationShowing: false,
    });
  }

  _handleRemoveConfirmation = () => {
    const {
      onProfileChange,
      trackClick,
      universalDriverId,
    } = this.props;

    trackClick(ANALYTICS_EVENTS.REMOVE_DRIVER_CONFIRM, { universalDriverId });

    this.setState({
      isConfirmationShowing: false,
    });
    onProfileChange(ProfileDriverService.removeDriver(this.state.profileParams, universalDriverId));
  }

  _handleSubmit = () => {
    this.props.trackClick(ANALYTICS_EVENTS.SUBMIT);

    this._forceValidation(async () => {
      if (!this._isValid()) {
        return;
      }

      this.setState({
        isValidatingLicense: true,
      });

      const originalDriver = ProfileDriverService.getDriver(this.props.profileParams, this.props.universalDriverId);
      const driver = this._getDriver();

      ProfileDriverService.setIsLicenseEdited(driver, originalDriver);

      const isValidLicenseNumber = await LicenseValidationService.isValidForMarket(driver.determineLicenseValidationParams());

      if (isValidLicenseNumber) {
        const finalProfileParams = ProfileDriverService.removeAnyMatchingExcludedDriver(this.state.profileParams, driver);
        this.props.onProfileChange(finalProfileParams);
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isValidatingLicense: false,
          errors: {
            ...prevState.errors,
            licenseNumber: 'Please enter a valid license number',
          },
        }));
      }
    });
  }

  _forceValidation = (callback) => {
    const driver = this._getDriver();
    const errors = Object.keys(this._getDriver()).reduce((allErrors, field) => ({
      ...allErrors,
      [field]: driverFieldValidator(driver, field, this.props.profileRulesContext[field]),
    }), {});

    this.setState({
      errors,
    }, callback);
  }

  _isValid = () => Object.values(this.state.errors).every((error) => !error);
  _isContinueDisabled = () => this.state.isValidatingLicense || !this._isValid();
  _getDriver = () => ProfileDriverService.getDriver(this.state.profileParams, this.props.universalDriverId);
  _displayPniToggle = (driver) => {
    const {
      deepLinked, profileParams, pniUniversalDriverId,
    } = this.props;

    return !deepLinked &&
             profileParams.drivers.length > 1 &&
             pniUniversalDriverId !== driver.universalDriverId;
  }

  _renderFields = () => {
    const driver = this._getDriver();
    return (
      <div>
        {this._displayPniToggle(driver) &&
          <ToggleField
            circleSelectedStyles={this.props.circleSelectedStyles}
            isSelected={!!driver.pni}
            onChange={this._handlePNIToggle}
            secondaryText={'This person is typically referred to as the "primary insured".'}
            testID={'pni'}
            text={'Will this driver own and manage the policy?'}
            toggleSelectedStyles={this.props.toggleSelectedStyles}
          />
        }
        <EditDriverNameFields
          errors={this.state.errors}
          firstName={driver.firstName}
          inputStyle={this.props.inputStyle}
          lastName={driver.lastName}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
        />
        <EditDriverDOBFields
          dob={driver.dob}
          errors={this.state.errors}
          inputStyle={this.props.inputStyle}
          isObfuscated={['day', 'month']}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
        />
        <EditDriverLicenseInfoFields
          ageWhenLicensed={driver.ageWhenLicensed}
          chevronStyle={this.props.chevronStyle}
          displayAgeWhenLicensed={this.props.profileRulesContext.ageWhenLicensed}
          dropdownIndicatorColor={this.props.dropdownIndicatorColor}
          errors={this.state.errors}
          gender={driver.gender}
          genderOptions={this.props.profileRulesContext.genderChoices}
          inputStyle={this.props.inputStyle}
          isObfuscated={true}
          licenseNumber={driver.licenseNumber}
          licenseState={driver.licenseState}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
          onLicenseChange={this._handleLicenseChange}
          optionStyles={this.props.optionStyles}
          persistLabel={true}
          selectBoxFocusedStyles={this.props.selectBoxFocusedStyles}
          selectBoxStyles={this.props.selectBoxStyles}
          useGender={this.props.profileRulesContext.useGender}
        />
        <EditDriverAdditionalInfoFields
          chevronStyle={this.props.chevronStyle}
          dropdownIndicatorColor={this.props.dropdownIndicatorColor}
          maritalStatus={driver.maritalStatus}
          maritalStatusOptions={this.props.profileRulesContext.maritalStatusChoices}
          onChange={this._handleChange}
          optionStyles={this.props.optionStyles}
          persistLabel={true}
          selectBoxFocusedStyles={this.props.selectBoxFocusedStyles}
          selectBoxStyles={this.props.selectBoxStyles}
        />
        <ProfileDriverStateSpecificRequirements
          circleSelectedStyles={this.props.circleSelectedStyles}
          driver={driver}
          onChange={this._handleToggle}
          profileRulesContext={this.props.profileRulesContext}
          toggleSelectedStyles={this.props.toggleSelectedStyles}
        />
      </div>
    );
  }

  _renderConfirmationDialog() {
    if (this.state.isConfirmationShowing) {
      return (
        <ConfirmationDialog
          buttonStyles={this.props.cssButtonOverrides}
          buttonText={'Remove driver'}
          content={'If you remove a driver from your policy they may not be covered while driving your car.'}
          heading={'Remove driver?'}
          linkStyles={this.props.linkStyles}
          onCancel={this._handleRemoveCancel}
          onConfirm={this._handleRemoveConfirmation}
        />
      );
    }
  }

  render() {
    const driver = this._getDriver();
    if (!driver) {
      return null;
    }

    const containerStyles = [styles.container];
    if (this.props.disableFixedWidths) { containerStyles.push(styles.disableFixedWidths); }

    const displayRemoveDriverLink = this.props.isOriginalDriver ? false : !driver.pni;
    return (
      <>
        {this._renderConfirmationDialog()}
        <ProfileHeading styles={styles.heading}>
          {'Edit the driver’s information.'}
        </ProfileHeading>
        <ProfileForm onSubmit={this._handleSubmit}>
          {this._renderFields()}
          <div css={styles.submitButtonWrapper}>
            <Button
              css={[this.props.cssButtonOverrides]}
              disabled={this._isContinueDisabled()}
            >
              Save changes
            </Button>
          </div>
          {displayRemoveDriverLink &&
          <div css={styles.linkContainer}>
            <Link
              css={[styles.removeDriverLink, this.props.linkStyles]}
              onClick={this._handleRemoveDriver}
            >
              Remove driver
            </Link>
          </div>
          }
        </ProfileForm>
      </>
    );
  }
}

export default EditDriverManager;

const styles = StyleSheet.create({
  submitButtonWrapper: {
    marginTop: 10,
  },
  driverNotFoundWrapper: {
    textAlign: 'center',
  },
  removeDriverLink: {
    color: Colors.red(),
    ':hover': {
      color: Colors.red(),
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 40,
  },
  heading: {
    marginTop: 0,
    marginBottom: 40,
    paddingRight: 24,
  },
});
