import CoverageNonLiabilityLinkModal from '@root/quotes/src/components/modals/coverage-non-liability-link-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

const PipCoverageOptionsExplanationLinkModal = ({
  primaryBrandColor,
  infoBrandColor,
}) => (
  <CoverageNonLiabilityLinkModal
    infoBrandColor={infoBrandColor}
    linkText={'Learn More'}
    modalName={'PIP Coverage Options Explanation Modal'}
    primaryBrandColor={primaryBrandColor}
  >
    <h1 css={styles.header}>Should I apply my PIP deductible and work loss coverage rejection to dependent relatives who live with me?</h1>
    <p css={styles.paragraph}>You can choose for these selections to apply not just to you, but to dependent relatives who live with you.</p>
    <p css={styles.paragraph}>Applying a deductible and rejection of work loss coverage to dependent relatives living with you can lower your quote.</p>
  </CoverageNonLiabilityLinkModal>
);

PipCoverageOptionsExplanationLinkModal.propTypes = {
  infoBrandColor: PropTypes.string,
  primaryBrandColor: PropTypes.string,
};

export default PipCoverageOptionsExplanationLinkModal;

const styles = StyleSheet.create({
  header: {
    lineHeight: 1.1,
    marginBottom: 24,
  },
  paragraph: {
    color: '#767676',
    fontSize: 18,
  },
});
