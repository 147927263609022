import Quote from '@root/quotes/src/models/quote';
import QuoteStrings from '@root/quotes/src/models/quote-strings';
import { DisplayQuote } from '@root/quotes/src/models/display-quote';
import { RECOMMENDATIONS as RIGHT_QUOTE_RECOMMENDATIONS } from '@root/right-quote/src/models/right-quote';

export default class QuotesContext {
  static build({
    cdwAcceptedDeclined = 'no_use',
    coreCoverages = [],
    displayQuotes = [],
    ineligibleDriversUnderwritingDeclined = false,
    isEstimate = false,
    manualTortMarket = false,
    market = null,
    monthlyFeesInCents = null,
    ncExternalCeded = false,
    quotes = [],
    ratingRequestId = null,
    rightQuoteRecommendation = RIGHT_QUOTE_RECOMMENDATIONS.INCOMPLETE,
    roadsideAcceptedDeclined = 'no_use',
    taxRates = {},
    tortSelection = 'no_use',
    underwritingDecision = null,
  } = {}) {
    const hasStateMinQuote = quotes?.some((quote) => quote.tier === QuoteStrings.TIERS.STATE_MINIMUM);
    let bottomTier;

    if (hasStateMinQuote) {
      bottomTier ||= QuoteStrings.TIERS.STATE_MINIMUM;
    } else {
      bottomTier ||= QuoteStrings.TIERS.LOW;
    }

    return Object.assign(
      new QuotesContext(),
      {
        cdwAcceptedDeclined,
        coreCoverages,
        manualTortMarket,
        displayQuotes: displayQuotes?.map(DisplayQuote.build),
        market,
        monthlyFeesInCents,
        ncExternalCeded,
        ineligibleDriversUnderwritingDeclined,
        isEstimate,
        quotes: quotes?.map(Quote.build),
        ratingRequestId,
        rightQuoteRecommendation,
        roadsideAcceptedDeclined,
        taxRates,
        tortSelection,
        underwritingDecision,
        bottomTier,
      }
    );
  }

  set(key, value) {
    return Object.assign(
      new QuotesContext(),
      this,
      {
        [key]: value,
      },
    );
  }

  getQuote(quoteId) {
    let found_quote = this.displayQuotes.find((quote) => quote.id === quoteId) ?? this.quotes.find((quote) => quote.id === quoteId);

    if (!found_quote) {
      found_quote = this.displayQuotes.find((quote) => quote.originQuoteId === quoteId) ?? this.quotes.find((quote) => quote.originQuoteId === quoteId);
    }

    return found_quote;
  }

  getQuoteByTier(tier) {
    return this.displayQuotes.find((quote) => quote.tier === tier) ?? this.quotes.find((quote) => quote.tier === tier);
  }

  getCheapestDisplayQuote() {
    return this.displayQuotes.reduce((cheapestQuote, quote) => {
      if (!cheapestQuote || quote.fullTermPayment.totalAmountInCents < cheapestQuote.fullTermPayment.totalAmountInCents) {
        return quote;
      }

      return cheapestQuote;
    }, null);
  }

  getRightQuote() {
    return this.displayQuotes.find((quote) => quote.isRightQuote) ?? this.quotes.find((quote) => quote.isRightQuote);
  }

  getQuotes() {
    const SUPPORTED_TIERS = [
      QuoteStrings.TIERS.CUSTOM,
      QuoteStrings.TIERS.HIGH,
      this.bottomTier,
      QuoteStrings.TIERS.RECOMMENDED,
      QuoteStrings.TIERS.RIGHT_QUOTE,
    ];

    let twoTierFilter = (quote) => quote.tier !== QuoteStrings.TIERS.RECOMMENDED;

    let rightQuotesFilter = () => true;
    if (this.rightQuoteRecommendation === RIGHT_QUOTE_RECOMMENDATIONS.INCLUDE) {
      twoTierFilter = () => true;
      rightQuotesFilter = (quote) =>
        quote.isRightQuote || quote.tier === this.bottomTier || quote.tier === QuoteStrings.TIERS.CUSTOM;
    } else if (this.rightQuoteRecommendation === RIGHT_QUOTE_RECOMMENDATIONS.WAIVE) {
      twoTierFilter = () => true;
      rightQuotesFilter = (quote) =>
        quote.isRightQuote || quote.tier === QuoteStrings.TIERS.HIGH || quote.tier === QuoteStrings.TIERS.CUSTOM;
    }

    const supportedTierFilter = (quote) => SUPPORTED_TIERS.includes(quote.tier);

    return this.displayQuotes
      .filter(supportedTierFilter)
      .filter(twoTierFilter)
      .filter(rightQuotesFilter)
      .sort((q1, q2) => q1.sortValue - q2.sortValue)
      ?? this.quotes
        .filter(supportedTierFilter)
        .filter(twoTierFilter)
        .filter(rightQuotesFilter)
        .sort((q1, q2) => q1.sortValue - q2.sortValue);
  }

  updateCustomQuote(customQuote) {
    const nonCustomQuotes = this.displayQuotes.filter((quote) => quote.tier !== QuoteStrings.TIERS.CUSTOM) ?? this.quotes.filter((quote) => quote.tier !== QuoteStrings.TIERS.CUSTOM);
    return this.set('quotes', [...nonCustomQuotes, customQuote]);
  }
}
