import PropTypes from '@root/vendor/prop-types';
import QuotesMobileSelect from '@root/quotes/src/components/form-elements/quotes-mobile-select';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { ListCardItem } from '@root/core/src/components/list-card';

export default function QuotesSelect(props) {
  return (
    <ListCardItem css={styles.listItemBottom}>
      <div css={styles.desktopWrapper}>
        <Select
          selectBoxStyles={styles.selectBox}
          {...props}
        />
      </div>
      <div css={styles.mobileWrapper}>
        <QuotesMobileSelect
          selectBoxStyles={styles.selectBox}
          {...props}
          inputId={`mobile-${props.inputId}`}
          inputName={`mobile-${props.inputName}`}
        />
      </div>
      {props.renderRightQuoteBadge &&
          props.renderRightQuoteBadge()
      }
      {props.children}
    </ListCardItem>
  );
}

QuotesSelect.propTypes = {
  children: PropTypes.node,
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  mobileSelectedOptionStyles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  renderRightQuoteBadge: PropTypes.func,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
  selectedValue: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  listItemBottom: {
    borderRadius: '0 0 10px 10px',
    border: `1px solid ${Colors.gray30()}`,
    background: Colors.nearWhite(),
  },
  selectBox: {
    color: Colors.black(),
  },
  mobileWrapper: {
    ...Responsive.md({
      display: 'none',
    }),
  },
  desktopWrapper: {
    display: 'none',
    ...Responsive.md({
      display: 'inherit',
    }),
  },
});
