import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCopy from '@root/quotes/src/components/coverage/coverage-copy';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import Link from '@root/core/src/components/link';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React, { useMemo } from '@root/vendor/react';
import RoadsideLearnMoreModal from '@root/quotes/src/components/modals/roadside-learn-more-modal';
import VehicleSelectionCard from '@root/quotes/src/components/coverage-options/vehicle-selection-card';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useState } from '@root/vendor/react';

export default function CoverageSectionRoadside({
  cardHeaderBackgroundColor,
  coverageSelections,
  customQuote,
  dropdownIndicator,
  IconComponent,
  infoBrandColor,
  mobileSelectedOptionStyles,
  onAcceptCoverages,
  onCoverageChange,
  onCoverageSelectClose,
  onCoverageSelectOpen,
  onDeclineCoverages,
  onVehicleToggle,
  optionStyles,
  profileParams,
  primaryBrandColor,
  quoteCoveragesContext,
  secondaryBrandColor,
  selectBoxFocusedStyles,
  selectBoxStyles,
  styleOverrides,
}) {
  const { trackClick } = useAnalytics('COVERAGE_SECTION_ROADSIDE', false);

  const [isShowingRoadsideInfoModal, setIsShowingRoadsideInfoModal] = useState(false);

  const styles = useMemo(() => stylesGenerator({ infoBrandColor }), [infoBrandColor]);

  const roadsideCoverageNotDeclinable = (coverages) => {
    if (!coverages.roadside) {
      return false;
    }
    return coverages.roadside.every((coverage) => !coverage.declined);
  };

  if (!quoteCoveragesContext.coverages.roadside?.length > 0 || roadsideCoverageNotDeclinable(quoteCoveragesContext.coverages)) {
    return null;
  }

  const handleVehicleToggle = (vehicle, selected) => {
    const roadsideCoverage = customQuote.getCoverage(Coverage.Symbols.ROADSIDE);
    onVehicleToggle(vehicle, selected, [roadsideCoverage]);
  };

  const handleAddCoverage = () => {
    const roadsideCoverage = customQuote.getCoverage(Coverage.Symbols.ROADSIDE);
    const coveredVins = profileParams.getSelectedVehiclesWithVin().map(({ vin }) => vin);

    const coverageWithVins = {
      ...roadsideCoverage,
      coveredVins,
    };

    onAcceptCoverages([coverageWithVins]);
  };

  const openLearnMoreModal = () => {
    trackClick('LEARN_MORE');

    setIsShowingRoadsideInfoModal(true);
  };

  const roadside = customQuote.getCoverage(Coverage.Symbols.ROADSIDE);
  const isCoverageAdded = !roadside.declined;
  const selectedVins = roadside.coveredVins;
  const hasSelectedVehicles = roadside.coveredVins.length > 0;
  const market = profileParams.market();

  const headingCopy = (
    <CoverageTitle
      coverage={roadside}
      market={market}
    />
  );

  const descriptionCopy = (
    <CoverageCopy
      additionalLink={(
        <Link
          cssOverrides={styles.linkStyles}
          onClick={openLearnMoreModal}
        >
          {'Learn more'}
        </Link>
      )}
      coverage={roadside}
      market={market}
    />
  );

  return (
    <>
      <CoverageGroup
        heading={Coverage.getLocaleName({
          market,
          symbol: Coverage.Symbols.ROADSIDE,
        })}
        hideDivider={true}
        styleOverrides={styleOverrides}
      >
        <VehicleSelectionCard
          description={'Included vehicles will have Roadside Assistance coverage.'}
          isCoverageAdded={isCoverageAdded}
          onToggle={handleVehicleToggle}
          selectedVins={selectedVins}
          toggleSelectedColor={secondaryBrandColor}
          vehicles={profileParams.vehicles}
        />
        <AddCoverageCard
          description={descriptionCopy}
          heading={headingCopy}
          IconComponent={IconComponent}
          isCoverageAdded={isCoverageAdded}
          onAddCoverage={handleAddCoverage}
          primaryBrandColor={primaryBrandColor}
        />
        <CoverageSelectionCard
          description={descriptionCopy}
          headerBackgroundColor={cardHeaderBackgroundColor}
          heading={headingCopy}
          isCoverageAdded={isCoverageAdded}
          onRemoveCoverage={() => onDeclineCoverages([roadside])}
          vehiclesSelected={hasSelectedVehicles}
        >
          <QuotesSelect
            dropdownIndicator={dropdownIndicator}
            dropdownIndicatorColor={secondaryBrandColor}
            inputId={'roadside-coverage-select'}
            inputName={'roadside-coverage-select'}
            mobileSelectedOptionStyles={mobileSelectedOptionStyles}
            onChange={(id) => onCoverageChange(id, Coverage.Symbols.ROADSIDE)}
            onClose={onCoverageSelectClose}
            onOpen={onCoverageSelectOpen}
            options={coverageSelections[Coverage.Symbols.ROADSIDE]}
            optionStyles={optionStyles}
            placeholder={'Select option'}
            selectBoxFocusedStyles={selectBoxFocusedStyles}
            selectBoxStyles={selectBoxStyles}
            selectedValue={customQuote.getCoverage(Coverage.Symbols.ROADSIDE).id}
          />
        </CoverageSelectionCard>
      </CoverageGroup>
      <RoadsideLearnMoreModal
        infoBrandColor={infoBrandColor}
        isShowing={isShowingRoadsideInfoModal}
        onCancel={() => setIsShowingRoadsideInfoModal(false)}
        primaryBrandColor={primaryBrandColor}
      />
    </>
  );
}

CoverageSectionRoadside.propTypes = {
  cardHeaderBackgroundColor: PropTypes.string,
  coverageSelections: PropTypes.object,
  customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
  dropdownIndicator: PropTypes.string,
  IconComponent: PropTypes.func,
  infoBrandColor: PropTypes.string,
  mobileSelectedOptionStyles: PropTypes.object,
  onAcceptCoverages: PropTypes.func.isRequired,
  onCoverageChange: PropTypes.func.isRequired,
  onCoverageSelectClose: PropTypes.func.isRequired,
  onCoverageSelectOpen: PropTypes.func.isRequired,
  onDeclineCoverages: PropTypes.func.isRequired,
  onVehicleToggle: PropTypes.func.isRequired,
  optionStyles: PropTypes.object,
  primaryBrandColor: PropTypes.string,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  quoteCoveragesContext: PropTypes.instanceOf(QuoteCoveragesContext).isRequired,
  secondaryBrandColor: PropTypes.string,
  selectBoxFocusedStyles: PropTypes.object,
  selectBoxStyles: PropTypes.object,
  styleOverrides: PropTypes.object,
};

const stylesGenerator = ({ infoBrandColor = Colors.rootOrange() }) => StyleSheet.create({
  linkStyles: {
    color: infoBrandColor,
    ':hover': {
      color: Colors.toRgba(infoBrandColor, 0.8),
    },
  },
});
