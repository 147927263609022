import FirstPaymentExplainerModal from '@root/payments/src/components/first-payment-explainer-modal';
import Money from '@root/core/src/models/money';
import PaymentBreakdownModal from '@root/payments/src/components/payment-breakdown-modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function FirstPaymentExplainer({
  analyticsPrefix,
  hasUncollectedPremium = false,
  prices,
  initialPremiumRatio,
  paymentBreakdown,
  postBindTestDriveRequired,
  styleOverrides = {},
}) {
  const [isShowing, setIsShowing] = useState(false);
  const [isShowingPaymentBreakdownModal, setIsShowingPaymentBreakdownModal] = useState(false);
  const { trackClick } = useAnalytics(`${analyticsPrefix}_PAYMENT_BREAKDOWN_MODAL`, false);

  const openModal = () => {
    trackClick('OPEN');
    setIsShowing(true);
  };
  const closeModal = () => {
    trackClick('CLOSE');
    setIsShowing(false);
  };

  const pricesInDollars = prices.map((amount) => amount / 100);
  const totalInDollars = prices.reduce((total, amount) => total + amount, 0) / 100;
  const showFirstPaymentLowerCopy = initialPremiumRatio < 1 && pricesInDollars[0] < pricesInDollars[1];

  const formattedPrice = Money.fromCents(prices[0]).formattedDollarsAndCents();
  const explainerCopy = () => {
    if (showFirstPaymentLowerCopy) {
      return 'Why is my first payment lower?';
    } else {
      return 'Why is my first payment different?';
    }
  };

  const _renderFirstPaymentExplainerModal = () => {
    return (
      <FirstPaymentExplainerModal
        dismiss={closeModal}
        hasUncollectedPremium={hasUncollectedPremium}
        isShowing={isShowing}
        pricesInDollars={pricesInDollars}
        shouldShowAsterisks={!!postBindTestDriveRequired}
        showFirstPaymentLowerCopy={showFirstPaymentLowerCopy}
        showPaymentBreakdown={() => {
          setIsShowing(false);
          setIsShowingPaymentBreakdownModal(true);
        }}
        styleOverrides={styleOverrides}
        totalInDollars={totalInDollars}
      />
    );
  };

  const _renderPaymentBreakdownModal = () => {
    return (
      <PaymentBreakdownModal
        isModalOpen={isShowingPaymentBreakdownModal}
        onClose={() => {
          setIsShowingPaymentBreakdownModal(false);
          setIsShowing(true);
        }}
        paymentBreakdown={paymentBreakdown}
      />
    );
  };

  return (
    <div css={styles.container}>
      <h3>{formattedPrice} to start today</h3>
      <button
        css={[styles.button, styleOverrides.modalOpenButton]}
        onClick={openModal}
      >
        {explainerCopy()}
      </button>
      {_renderFirstPaymentExplainerModal()}
      {paymentBreakdown && _renderPaymentBreakdownModal()}
    </div>
  );
}

FirstPaymentExplainer.propTypes = {
  analyticsPrefix: PropTypes.string,
  hasUncollectedPremium: PropTypes.bool,
  initialPremiumRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentBreakdown: PropTypes.array,
  postBindTestDriveRequired: PropTypes.bool,
  prices: PropTypes.array,
  styleOverrides: PropTypes.shape({
    modalBarChartBarFill: PropTypes.object,
    modalBarChartBottomLabel: PropTypes.object,
    modalCloseButton: PropTypes.object,
    modalOpenButton: PropTypes.object,
  }),
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 22,
    '& > *': { margin: 0 },
    '& > * + *': { marginTop: '0.5rem' },
  },
  text: {
    ...Theme.cardContent(),
    fontSize: 16,
  },
  button: {
    ...Theme.textLink(),
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    '&:focus:not(&:focus-visible)': {
      outline: 'none',
    },
  },
});
