import Coverage from '@root/auto-pricing/src/models/coverage';
import Quote from '@root/quotes/src/models/quote';
import cloneDeep from '@root/vendor/lodash/cloneDeep';
import intersection from '@root/vendor/lodash/intersection';
import { DisplayQuote } from '@root/quotes/src/models/display-quote';
import { RootError } from '@root-common/root-errors';
import { fixupCoverages } from '@root/vendor/quoting-rules';
import { fromQuotingData, toQuotingData } from '@root/quotes/src/models/quoting-data-adapter';

export default class CustomQuote extends Quote {
  static build({
    quoteCoveragesContext,
    ...rest
  }) {
    const quote = DisplayQuote.build(rest);
    const coverages = CustomQuote.buildCoverages(quote.coverages, quoteCoveragesContext);

    return Object.assign(
      new CustomQuote(),
      quote,
      {
        coverages,
        quoteCoveragesContext,
      }
    );
  }

  static buildCoverages(coverages = [], quoteCoveragesContext) {
    return coverages.map((coverage) => {
      if (quoteCoveragesContext) {
        const coveragePremiums = {
          approximatePremiumCents: null,
          approximateMonthlyPremiumCents: null,
          rawApproximatePremiumCents: null,
        };

        const coveragesForSymbol = quoteCoveragesContext.coverages[coverage.symbol];
        const coverageFromContext = coveragesForSymbol ? coveragesForSymbol.find((c) => c.id === coverage.id) : null;

        if (coverageFromContext) {
          coveragePremiums.approximatePremiumCents = coverageFromContext.approximatePremiumCents;
          coveragePremiums.approximateMonthlyPremiumCents = coverageFromContext.approximateMonthlyPremiumCents;
          coveragePremiums.rawApproximatePremiumCents = coverageFromContext.rawApproximatePremiumCents;
        }

        return Coverage.build({
          ...coverage,
          ...coveragePremiums,
        });
      }

      return coverage;
    });
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes) {
    return Object.assign(
      new CustomQuote(),
      this,
      attributes
    );
  }

  setCoverage(coverage) {
    const coverages = this.coverages.map((c) => {
      if (c.symbol === coverage.symbol) {
        return coverage;
      }
      return c;
    });
    return this.set('coverages', coverages);
  }

  updateCoverage(coverage) {
    const currentCoverage = this.getCoverage(coverage?.symbol);
    const updatedCoverage = Object.assign(new Coverage(), coverage, {
      coveredVins: currentCoverage?.coveredVins,
    });
    return this.setCoverage(updatedCoverage);
  }

  updateQuoteCoveragesContext(quoteCoveragesContext) {
    const oldCoverages = this.coverages;

    const newCoverages = oldCoverages.map((s) => {
      return quoteCoveragesContext.coverages[s.symbol].filter((qcc) => qcc.id === s.id)[0];
    });

    let newCustomQuote = Object.assign(
      new CustomQuote(),
      this,
      {
        quoteCoveragesContext,
      }
    );

    newCoverages.forEach((c) => {
      newCustomQuote = newCustomQuote.updateCoverage(c);
    });

    return newCustomQuote;
  }

  acceptCoverages(coverages) {
    let quote = this;

    coverages.forEach((coverage) => {
      let defaultCoverageId = this.quoteCoveragesContext.defaultCoverages[coverage.symbol]?.coverage?.id;

      if (!defaultCoverageId) {
        defaultCoverageId = this.quoteCoveragesContext.coverages[coverage.symbol].find((c) => !c.declined).id;
      }

      const defaultCoverage = this.quoteCoveragesContext.getCoverageByIdAndSymbol(defaultCoverageId, coverage.symbol).set('coveredVins', coverage.coveredVins);
      quote = quote.setCoverage(defaultCoverage);
    });

    return quote;
  }

  declineCoverages(coverages) {
    return coverages.reduce((customQuote, coverage) => customQuote.declineCoverage(coverage), this);
  }

  declineCoverage(coverage) {
    const declinedCoverage = this.quoteCoveragesContext.coverages[coverage.symbol].find((c) => c.declined);
    return this.setCoverage(declinedCoverage);
  }

  coverageIsDeclinable(coverage) {
    return this.quoteCoveragesContext.coverages[coverage.symbol].some((c) => c.declined);
  }

  addVinToCoverages(vin, coverages) {
    return coverages.reduce((customQuote, coverage) => customQuote.addVinToCoverage(vin, coverage), this);
  }

  addVinToCoverage(vin, coverage) {
    const newCoverage = this.getCoverage(coverage.symbol).set('coveredVins', [vin, ...coverage.coveredVins]);
    return this.setCoverage(newCoverage);
  }

  removeVinFromCoverages(vin, coverages) {
    let customQuote = this;

    coverages.forEach((coverage) => {
      customQuote = customQuote.removeVinFromCoverage(vin, coverage);
    });

    return customQuote;
  }

  removeVinFromCoverage(vin, coverage) {
    const coveredVins = this.getCoverage(coverage.symbol).coveredVins.filter((v) => vin !== v);
    const newCoverage = this.getCoverage(coverage.symbol).set('coveredVins', coveredVins);
    return this.setCoverage(newCoverage);
  }

  getDeductibleInfo() {
    const coll = this.getCoverage(Coverage.Symbols.COLLISION);
    const comp = this.getCoverage(Coverage.Symbols.COMPREHENSIVE);

    return {
      coverages: [coll, comp],
      coveredVins: coll.coveredVins,
      hasSelectedVehicles: !!coll.coveredVins.length,
      isCoverageAdded: !coll.declined,
    };
  }

  getFullCoverageIndicator(profileVins = []) {
    const fullCoverageVins = this.getFullCoverageVins();
    if (fullCoverageVins.length === 0) {
      return Coverage.FullCoverageIndicator.NONE;
    } else if (fullCoverageVins.length === profileVins.length) {
      return Coverage.FullCoverageIndicator.ALL;
    } else {
      return Coverage.FullCoverageIndicator.SOME;
    }
  }

  getFullCoverageVins() {
    const coll = this.getCoverage(Coverage.Symbols.COLLISION);
    const comp = this.getCoverage(Coverage.Symbols.COMPREHENSIVE);
    const bi = this.getCoverage(Coverage.Symbols.BODILY_INJURY);
    const pd = this.getCoverage(Coverage.Symbols.PROPERTY_DAMAGE);

    if (coll.declined || comp.declined || bi.declined || pd.declined) {
      return [];
    }

    return intersection(coll.coveredVins, comp.coveredVins, bi.coveredVins, pd.coveredVins);
  }

  updatePrices(profileVins = []) {
    const coverageIndicator = this.getFullCoverageIndicator(profileVins);
    const initPrices = {
      priceInCentsWithDiscount: 0,
      priceInCentsWithoutDiscount: 0,
    };
    const { vehicleCoverageSymbols } = this.quoteCoveragesContext;

    const accumPrices = Object.values(this.coverages).reduce((accum, coverage) => {
      if (!coverage.declined && coverage.approximatePremiumCents && coverage.rawApproximatePremiumCents) {
        if (vehicleCoverageSymbols.includes(coverage.symbol)) {
          coverage.coveredVins.forEach((vin) => {
            const vinCoverage = this.quoteCoveragesContext.getCoverageByIdVinSymbol(coverage.id, vin, coverage.symbol);

            accum.priceInCentsWithDiscount += vinCoverage?.approximatePremiumCents[coverageIndicator] || 0;
            accum.priceInCentsWithoutDiscount += vinCoverage?.rawApproximatePremiumCents[coverageIndicator] || 0;
          });
        } else {
          accum.priceInCentsWithDiscount += coverage.approximatePremiumCents[coverageIndicator];
          accum.priceInCentsWithoutDiscount += coverage.rawApproximatePremiumCents[coverageIndicator];
        }
      }

      return accum;
    }, initPrices);

    const monthlyPriceInCents = Math.round(accumPrices.priceInCentsWithoutDiscount / 6.0);
    const fullTermPayment = this._updateTermPaymentPremiumCharge(this.fullTermPayment, accumPrices.priceInCentsWithDiscount);

    const realPrices = {
      fullTermPayment,
    };

    realPrices.monthlyTermPayments = this.premiumRatios.map((ratio, index) => {
      const newPremiumWithCoverageChangesAndPremiumRatioApplied = Math.floor(ratio * monthlyPriceInCents);
      return this._updateTermPaymentPremiumCharge(this.monthlyTermPayments[index], newPremiumWithCoverageChangesAndPremiumRatioApplied);
    });
    return this.setAttributes(realPrices);
  }

  addTaxAndFees(quotesContext) {
    const premiumAmount = this.fullTermPayment.charges.find((charge) => charge.name === 'Premium').amountInCents;
    const premiumAmountWithTaxes = CustomQuote.applyTax(quotesContext, premiumAmount);
    const fullTermPayment = this._updateTermPaymentPremiumCharge(this.fullTermPayment, premiumAmountWithTaxes);

    const monthlyTermPayments = this.monthlyTermPayments.map((monthlyTermPayment) => {
      const monthlyPremiumAmount = monthlyTermPayment.charges.find((charge) => charge.name === 'Premium').amountInCents;
      const monthlyPremiumAmountWithTaxes = CustomQuote.applyTax(quotesContext, monthlyPremiumAmount);
      return this._updateTermPaymentPremiumCharge(monthlyTermPayment, monthlyPremiumAmountWithTaxes);
    });

    return this.setAttributes({
      monthlyTermPayments,
      fullTermPayment,
    });
  }

  static applyTax(quotesContext, amount) {
    const tax = quotesContext.taxRates.stateTaxRate * amount + quotesContext.taxRates.localTaxRate * amount;
    return Math.round(amount + tax);
  }

  getOrderedCoveragesWithOptionsBySymbols(symbols, options) {
    return symbols
      .filter((symbol) => this.coverages.some((coverage) => symbol === coverage.symbol && options[symbol].length))
      .map((symbol) => this.coverages.find((c) => symbol === c.symbol));
  }

  getCoverageHash() {
    return this.coverages.reduce((accum, coverage) => {
      accum[coverage.symbol] = coverage;
      return accum;
    }, {});
  }

  getVehicleAndNonVehicleCoverageIds(profileVins) {
    const coverageHash = this.getCoverageHash();

    const coverageParams = {
      nonVehicleCoverages: {},
      vehicleCoverages: [],
    };

    const { vehicleCoverageSymbols, nonVehicleCoverageSymbols } = this.quoteCoveragesContext;

    nonVehicleCoverageSymbols.forEach((symbol) => {
      coverageParams.nonVehicleCoverages[symbol] = coverageHash[symbol] ? coverageHash[symbol].id : null;
    });

    coverageParams.vehicleCoverages = profileVins.map((vin) => {
      const vinCoverages = {
        vin,
      };

      vehicleCoverageSymbols.forEach((symbol) => {
        if (!coverageHash[symbol]) {
          vinCoverages[symbol] = null;
        } else if (!coverageHash[symbol].coveredVins.includes(vin)) {
          const declinedCoverage = this.quoteCoveragesContext.coverages[symbol].find((c) => c.declined);

          if (!declinedCoverage) {
            const error = new RootError({
              message: 'No declined coverage for symbol in quoteCoveragesContext',
              name: 'CustomQuoteError',
            });
            error.additionalData = {
              profileVins, // temporary addition to debug this error
              vin,
              symbolCoverageHash: coverageHash[symbol],
              symbol,
              quoteCoveragesContext: this.quoteCoveragesContext.coverages[symbol],
            };
            throw error;
          }

          vinCoverages[symbol] = declinedCoverage.id;
        } else {
          vinCoverages[symbol] = coverageHash[symbol].id;
        }
      });

      return vinCoverages;
    });

    return coverageParams;
  }

  buildCustomCoveragesForRequest(profileParams) {
    const profileVins = profileParams.vehicles.map((v) => {
      if (!v.getAvailableVin()) {
        return v.getAvailableVin();
      }
      return v.getAvailableVin().toUpperCase();
    });

    return this.getVehicleAndNonVehicleCoverageIds(profileVins);
  }

  fixupCoverages(quotingRules, updatedSymbol) {
    const quotingData = toQuotingData(this);

    // Errors are returned but currently ignored. These will be reported
    // to sentry once all the known issues have been resolved
    const [result] = fixupCoverages({
      quotingRules: quotingRules.rules,
      quotingData,
      updatedSymbol,
    });

    const { selectedCoverages } = fromQuotingData(result);
    return this.set('coverages', selectedCoverages);
  }

  _coverageAcceptedAndCoversVins(coverage, vins) {
    return !coverage.declined && vins.every((vin) => coverage.coveredVins.includes(vin));
  }

  _updateTermPaymentPremiumCharge(termPayment, newAmount) {
    let totalAmountInCents = 0;

    const charges = cloneDeep(termPayment.charges);
    charges.forEach((charge) => {
      if (charge.name === 'Premium') {
        charge.amountInCents = newAmount;
      }
      totalAmountInCents += charge.amountInCents;
    });

    return {
      charges,
      totalAmountInCents,
    };
  }
}
